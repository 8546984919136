<template>
  <div class="fade-in">
    <h2 class="uppercase text-2xl mb-2">
      In Orbit...
    </h2>
    <p class="mb-2 text-gray-100">
      <!-- eslint-disable-next-line -->
      <span v-if="
        executiveReport
        && executiveReport.initiatedBy
        && executiveReport.initiatedBy.email
      ">
        <span class="font-semibold text-white">
          {{ executiveReport.initiatedBy.email }}
        </span>
        will receive an email when the report is ready to view.
      </span>
    </p>
    <p class="mb-6 text-gray-100">
      Please be patient - orbits can run anywhere from 2 hours to 48 hours.
    </p>
    <ul>
      <li v-for="(desc, index) in stepDesc"
          class="flex items-center uppercase text-gray-200 mb-4"
          :key="`step-${index}}`">
        <transition name="fade" mode="in-out">
          <CheckIcon v-if="step >= index" :key="`step-${index}-check`" />
          <CircleIcon v-else :key="`step-${index}-circle`" />
        </transition>
        <span class="ml-2 transition-colors duration-150 ease-linear"
              :class="{'text-white': step >= index}">{{ desc }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CircleIcon from '@/assets/circle.svg?inline';
import CheckIcon from '@/assets/circle_checked.svg?inline';

export default {
  name: 'ProgressReport',
  components: {
    CircleIcon,
    CheckIcon,
  },
  data() {
    return {
      stepDesc: [
        'Probes launched into orbit',
        'Discovering assets in perimeter',
        'Analyzing discovered assets',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'executiveReport',
    ]),
  },
  props: {
    /*
      The index of which step has been completed.
     */
    step: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  position: absolute;
  transition: opacity 150ms ease;
}
.fade-enter, .fade-leave-to {
  position: absolute;
  opacity: 0;
}

.fade-in {
  animation: enter 1000ms linear both;
  animation-delay: 1s;
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
