<template>
  <div class="checkbox">
    <input
      @change="updateValue"
      :id="id"
      type="checkbox"
      ref="checkbox"
      class="checkbox-input"
      :checked="isChecked"
      :disabled="disabled"/>
    <label
      class="checkbox-label noselect "
      :for="id">
      {{ label }}
    </label>
  </div>
</template>
<script>
export default {
  name: 'Checkbox',
  computed: {
    isChecked() {
      if (Array.isArray(this.checked)) {
        return this.checked.includes(this.value);
      }
      return this.checked;
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    updateValue() {
      if (Array.isArray(this.checked)) {
        const checkedItems = this.checked.slice();
        if (checkedItems.indexOf(this.value) !== -1) {
          checkedItems.splice(checkedItems.indexOf(this.value), 1);
        } else {
          checkedItems.push(this.value);
        }
        this.$emit('change', checkedItems);
      } else {
        this.$emit('change', !this.checked);
      }
    },
  },
};
</script>
<style scoped>
.checkbox {
  @apply inline-block relative pl-4 h-4;
  min-height: 1.5rem;
}

.checkbox-input {
  @apply cursor-pointer absolute z-10 m-0 opacity-0 w-4 h-4 left-0;
  top: 0.25rem;
}

.checkbox-label {
  @apply inline-block mb-0 cursor-pointer pl-1 text-gray-200 font-semibold;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: color 0.2s ease;

  &::before {
     @apply absolute w-4 h-4 border border-solid border-gray-200 left-0 rounded bg-gray-700;
     top: 0.25rem;
     content: "";
     transition: border-color 0.2s ease;
  }

  &::after {
     @apply absolute opacity-0 w-2 h-2 top-0 left-0 rounded-sm bg-white;
     content: "";
     left: 4px;
     top: 0.5rem;
     transition: background-color 0.2s ease, opacity 0.2s ease;
  }
}

.checkbox-input:checked + .checkbox-label {
  @apply text-white;
}

.checkbox-input:checked + .checkbox-label::after {
  @apply opacity-100;
}

.checkbox-input:checked + .checkbox-label::before {
  @apply border-white bg-gray-700;
}

.checkbox-input:focus + .checkbox-label::before,
.checkbox-input:hover + .checkbox-label::before {
  @apply border-blue-100 outline-none shadow-glowblue100;
}

.checkbox-input:checked:focus + .checkbox-label::before,
.checkbox-input:checked:hover + .checkbox-label::before {
  @apply border-blue-100 outline-none shadow-glowblue100;
}

.checkbox-input:active + .checkbox-label::before,
.checkbox-input:checked:active + .checkbox-label::before{
  @apply shadow-none;
}

.checkbox-input:checked:focus + .checkbox-label::after,
.checkbox-input:checked:hover + .checkbox-label::after {
  @apply bg-blue-100;
}

/* Disabled styles */
.checkbox-input:disabled {
  @apply cursor-default;
}

.checkbox-input:disabled + .checkbox-label {
  @apply cursor-default;
}

.checkbox-input:disabled:checked + .checkbox-label {
  @apply text-gray-600;
}

.checkbox-input:disabled:checked:hover + .checkbox-label::before {
  @apply border-gray-600 cursor-default outline-none;
}
@media print {
  .checkbox-label:before, .checkbox-label:after {
    display: none !important;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
