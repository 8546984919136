<template>
  <div>
    <div class="flex mb-3">
      <h2 class="relative inline-flex uppercase pl-2 py-1 bg-gray-900
                 border-t border-l border-b mr-4
                 "
          :class="[
            {'triangle--yellow border-yellow-500': showCriticalStyles},
            {'triangle border-gray-600': showDefaultStyles},
            ]">
        <span class="relative inline-block z-10
                     print:tracking-widest print:text-black print:font-semibold">
          {{ threatName[threatLevel]}} Threat
        </span>
      </h2>
      <div class="ml-2 w-3 self-stretch border border-yellow-500 transform skew-x-20
                  bg-yellow-500"></div>
      <div class="ml-2 w-3 self-stretch border transform skew-x-20"
           :class="[
            {'bg-yellow-500 border-yellow-500': threatLevel === 2 || threatLevel === 1},
            {'bg-gray-900 border-gray-600': threatLevel === 0},
            ]"></div>
      <div class="ml-2 w-3 self-stretch border transform skew-x-20"
           :class="[
            {'bg-yellow-500 border-yellow-500': showCriticalStyles},
            {'bg-gray-900 border-gray-600': showDefaultStyles},
            ]"></div>
    </div>
    <p class="text-sm mb-2 text-gray-200 border border-gray-600 p-2">
      <template v-if="threatLevel === 2">
        These emails and associated plain-text passwords have been leaked.
      </template>
      <template v-if="threatLevel === 1">
        These emails and associated hashed passwords have been leaked.<br>
        The plain-text password could potentially be recovered from the hashed password.
      </template>
      <template v-if="threatLevel === 0">
        These emails were included in data breaches, but no associated passwords were leaked.
      </template>
    </p>
    <div
      v-if='!isTrial'
      class="p-2 overflow-y-auto print:overflow-y-visible overflow-x-visible"
          :class="[
              {'cred-scroll-area--tall': showCriticalStyles},
              {'cred-scroll-area': showDefaultStyles},
              ]">

      <div class="relative border"
           :class="[
              {'bg-yellow-900 border-gray-900 print:bg-white': showCriticalStyles},
              {'bg-gray-800 border-gray-600': showDefaultStyles},
              ]">
        <div class="outcome-border-tb print:hidden"
             :class="[
                    {'outcome-border-tb--yellow': showCriticalStyles},
                  ]"></div>
        <div class="outcome-border-lr print:hidden"
             :class="[
                    {'outcome-border-lr--yellow': showCriticalStyles},
                  ]"></div>
        <div class="outcome-corners outcome-corners--yellow"
             :class="{'hidden': showDefaultStyles}"></div>
        <div v-for="(cred, index) in expanded ? credentials : credentials.slice(0, defaultRowCount)"
             :class="[
               {'border-yellow-500': showCriticalStyles},
               {'border-gray-600': showDefaultStyles},
               {'border-t': index !== 0},
             ]"
             :key="`credential-${index}`">
          <div class="flex items-center pt-2 pb-1 px-2 print bg:white">
            <UserIcon class="h-3 mr-2"
                      :class="[
                         {'text-yellow-500': showCriticalStyles},
                         {'text-gray-400': showDefaultStyles},
                       ]"/>
            <span class="truncate print:text-gray-400">{{ cred.username }}</span>
          </div>
          <div class="w-full h-px my-1"
               :style="{
                  backgroundImage: `url(${showCriticalStyles ? LineDashedYellow : LineDashed})`
                }" />
          <div class="flex items-center pb-3 pt-1 px-2">
            <PasswordIcon class="h-3 mr-2"
                          :class="[
                         {'text-yellow-500': showCriticalStyles},
                         {'text-gray-400': showDefaultStyles},
                       ]"/>
            <span v-if="threatLevel === 2 && cred.password" class="truncate print:text-gray-400">
              {{ cred.password }}
            </span>
            <span v-else-if="threatLevel === 1" class="uppercase text-gray-400">
              HASHED PASSWORD AVAILABLE
            </span>
            <span v-else-if="threatLevel === 0" class="uppercase text-gray-400">
              PASSWORD UNAVAILABLE
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="relative border  mt-3" v-else
           :class="[
              {'bg-yellow-900 border-gray-900': threatLevel === 2},
              {'bg-gray-800 border-gray-600': threatLevel !== 2},
              ]">
        <div class="outcome-border-tb print:hidden"
             :class="[
                    {'outcome-border-tb--yellow': threatLevel === 2},
                  ]"></div>
        <div class="outcome-border-lr print:hidden"
             :class="[
                    {'outcome-border-lr--yellow': threatLevel === 2},
                  ]"></div>
        <div class="outcome-corners outcome-corners--yellow"
             :class="{'hidden': threatLevel !== 2}"></div>
      <p class="p-2">{{ trialCount }} {{ 'credential' | pluralize(trialCount) }} discovered</p>
    </div>
    <div v-if="defaultRowCount < credentials.length"
      class="border-b border-r border-l bg-gray-800 border-gray-600 print:hidden">
      <Button type="text-small"
              class="w-full py-2 text-gray-300"
              @onClick="expanded = !expanded">
        {{ expanded ? 'Collapse' : 'Expand' }}
        <template v-slot:iconRight>
          <Chevron class="text-gray-200 transform
                            transition-transform duration-200 linear"
                   :class="{'-rotate-180': expanded}"/>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import UserIcon from '@/assets/at_symbol.svg?inline';
import PasswordIcon from '@/assets/asterisk--noglow.svg?inline';
import LineDashedYellow from '@/assets/line_dashed--yellow.svg';
import LineDashed from '@/assets/line_dashed.svg';
import Chevron from '@/assets/chevron.svg?inline';
import Button from '@/components/Button.vue';

export default {
  name: 'LeakedCredentialsList',
  components: {
    UserIcon,
    PasswordIcon,
    Chevron,
    Button,
  },

  props: {
    /* An Array of credential objects with `password` and `username` keys */
    credentials: {
      type: Array,
      default: () => [],
    },
    /* Threat level (0, 1, 2) that determines look of box */
    threatLevel: {
      type: Number,
      required: true,
    },
    /* Number of credentials to display before user needs to expand */
    defaultRowCount: {
      type: Number,
      required: true,
    },
    /* Will not render list of leaked creds for trial users */
    isTrial: {
      type: Boolean,
      required: true,
    },
    /* For trial users will render total */
    trialCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      LineDashedYellow,
      LineDashed,
      threatName: [
        'Low',
        'Medium',
        'Critical',
      ],
      expanded: false,
    };
  },
  computed: {
    isPrint() {
      return window.matchMedia('print').matches;
    },
    showCriticalStyles() {
      return !this.isPrint && this.threatLevel === 2;
    },
    showDefaultStyles() {
      return this.threatLevel !== 2 || this.isPrint;
    },
  },
};
</script>

<style scoped>
.triangle::before {
  @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.75rem);
  width: calc(1rem + 12px);
  height: calc(100% + 2px);
  content: '';
}

.triangle--yellow::before {
  @apply absolute border-b bg-gray-900 border-t border-yellow-500 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.75rem);
  width: calc(1rem + 12px);
  height: calc(100% + 2px);
  content: '';
}

.outcome-corners {
  @apply absolute pointer-events-none;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.outcome-corners--yellow {
  background:
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}
@media print {
  .outcome-corners--yellow {
    background: unset;
  }
}

.outcome-border-tb {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.outcome-border-tb--yellow {
  background:
    linear-gradient(to right, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 0,
    linear-gradient(to left, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 0,
    linear-gradient(to right, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 100%,
    linear-gradient(to left, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50% 2px;
}

@media print {
  .outcome-border-tb--yellow {
    background: unset;
  }
}

.outcome-border-lr {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.outcome-border-lr--yellow {
  background:
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 0,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 0,
    linear-gradient(to top, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 100%,
    linear-gradient(to top, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 2px 50%;
}
@media print {
  .outcome-border-lr--yellow {
    background: unset;
  }
}
.cred-scroll-area {
  max-height: 18rem;
}
@media print {
  .cred-scroll-area {
    max-height: unset;
  }
}
.cred-scroll-area--tall {
  max-height: 45rem;
}
@media print {
  .cred-scroll-area--tall {
    max-height: unset;
  }
}
</style>
