var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex flex-col justify-center w-full bg-gray-900 border border-gray-600 print:bg-white",class:[
      {'print:border-orange-400': _vm.hasAttackPathways},
      {'print:border-green-500': !_vm.hasAttackPathways},
    ]},[_c('div',{staticClass:"attack-pathways-header",class:[
      {'attack-pathways-header--inset': _vm.hasInsetHeader},
    ]},[_c('div',{staticClass:"warning-bar h-8 sm:h-12 print:hidden",class:[
                 {'warning-bar--green': !_vm.hasAttackPathways},
                 {'warning-bar--yellow': _vm.hasAttackPathways}
               ]},[_c('div',{staticClass:"warning-bar-inner",class:[
                 {'warning-bar-inner--green': !_vm.hasAttackPathways},
                 {'warning-bar-inner--yellow': _vm.hasAttackPathways}
               ]}),_c('div',{staticClass:"warning-bar-corners",class:[
                 {'warning-bar-corners--green': !_vm.hasAttackPathways},
                 {'warning-bar-corners--yellow': _vm.hasAttackPathways}
               ]})]),_c('div',{staticClass:"inline-block px-6 sm:px-12 py-3 border transform -skew-x-40 print:bg-white",class:[
               {'bg-green-900 border-green-500': !_vm.hasAttackPathways},
               {'bg-yellow-900 border-yellow-500 print:border-orange-400': _vm.hasAttackPathways}
             ]},[_c('h1',{staticClass:"inline-block uppercase font-medium text-lg sm:text-2xl transform skew-x-40 print:tracking-widest print:text-black print:font-semibold"},[_vm._v(" Attack Pathways ["+_vm._s(_vm.attackPathwaysCount)+"] ")])])]),(!_vm.hasAttackPathways)?_c('div',{staticClass:"px-12 py-24 text-center mt-6 print:py-8"},[_c('h2',{staticClass:"uppercase font-medium tracking-widest text-lg"},[_vm._v(" No Attack Pathways Discovered ")]),_c('p',{staticClass:"text-gray-200"},[_vm._v(" Great job! Our orbital reconnaissance did not uncover any attack pathways. ")])]):_c('div',{staticClass:"mt-6"},[_c('p',{staticClass:"hidden text-gray-700 mx-auto italic text-center my-2 print:block"},[_vm._v(" Based on our findings, these are the steps an attacker could take against you. ")]),_c('div',{staticClass:"p-6 print:p-0"},[_vm._t("pathways")],2),(_vm.isShowingPrevious && _vm.hasResolvedAttackPathways)?_c('div',{staticClass:"w-full border-t border-gray-600 px-6 pt-6 mt-4"},[_c('div',{staticClass:"flex -mt-12 w-full justify-center"},[_c('h2',{staticClass:"relative inline-flex uppercase px-4 py-1 bg-gray-900 border border-gray-600 transform skew-x-20"},[_c('span',{staticClass:"relative inline-block z-10 text-2xl transform -skew-x-20"},[_vm._v(" Resolved Pathways ["+_vm._s(_vm.resolvedAttackPathwaysCount)+"] ")])])]),(_vm.showResolvedPathways)?_c('div',{staticClass:"mt-4"},[_vm._t("resolvedPathways")],2):_vm._e(),_c('Button',{staticClass:"w-full py-4 text-gray-300",attrs:{"type":"text-small"},on:{"onClick":function($event){_vm.showResolvedPathways = !_vm.showResolvedPathways}},scopedSlots:_vm._u([{key:"iconRight",fn:function(){return [_c('Chevron',{staticClass:"text-gray-200 transform transition-transform duration-200 linear",class:{'-rotate-180': _vm.showResolvedPathways}})]},proxy:true}],null,false,3408729492)},[_vm._v(" "+_vm._s(_vm.showResolvedPathways ? 'Hide' : 'Show')+" ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }