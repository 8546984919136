<template>
  <div class="bg-gray-900 border border-gray-600
  print:bg-white print:border-gray-200">
    <h3 class="font-semibold uppercase text-gray-300 text-sm p-4">
      Filter by Technology
    </h3>
    <div v-if="technologies.length">
      <div v-if="unfavorableTech.length"
        class="pb-2 border-b border-gray-600">
        <div class="flex items-center ml-4 py-2">
          <WarningIcon class="mr-1" />
          <span class="uppercase text-yellow-500 text-xs font-semibold">Unfavorable Tech</span>
        </div>
        <ul>
          <li v-for="(tech, index) in distinctTechnology(unfavorableTech)"
              :key="`unfavorable-tech-${index}`">
            <FilterCheckbox :id="`favorabletech-checkbox-${index}`"
                            color="blue"
                            :value="tech.description"
                            :label="tech.description"
                            v-model="selectedTechnologies"/>
          </li>
        </ul>
      </div>
      <div v-if="favorableTech.length"
        class="pb-2 border-b border-gray-600">
        <div class="flex items-center ml-4 py-2">
          <FavorableIcon class="mr-1" />
          <span class="uppercase text-green-500 text-xs font-semibold">Favorable Tech</span>
        </div>
        <ul>
          <li v-for="(tech, index) in distinctTechnology(favorableTech)"
              :key="`favorable-tech-${index}`">
            <FilterCheckbox :id="`unfavorabletech-checkbox-${index}`"
                            color="blue"
                            :value="tech.description"
                            :label="tech.description"
                            v-model="selectedTechnologies"/>
          </li>
        </ul>
        <p v-if="favorableTech.length === 0"
           class="uppercase font-medium tracking-widest text-base text-gray-400">
          N/A
        </p>
      </div>
      <div v-if="otherTech.length"
        class="pb-2">
        <div class="flex items-center ml-4 py-2">
          <span class="uppercase text-gray-300 text-xs font-semibold">Other Tech</span>
        </div>
        <ul>
          <li v-for="(tech, index) in distinctTechnology(otherTech)"
              :key="`other-tech-${index}`">
            <FilterCheckbox :id="`othertech-checkbox-${index}`"
                            color="blue"
                            :value="tech.description"
                            :label="tech.description"
                            v-model="selectedTechnologies"/>
          </li>
        </ul>
        <p v-if="otherTech.length === 0"
           class="uppercase font-medium tracking-widest text-base text-gray-400">
          N/A
        </p>
      </div>
    </div>
    <div v-else
         class="px-4 pt-4 pb-8 text-center">
      <p class="uppercase font-medium tracking-widest text- text-gray-200">
        No Technologies to Filter
      </p>
    </div>
  </div>
</template>

<script>
import FilterCheckbox from '@/components/FilterCheckbox.vue';
import WarningIcon from '@/assets/warning.svg?inline';
import FavorableIcon from '@/assets/favorable.svg?inline';

export default {
  name: 'FilterTechnology',
  components: {
    FilterCheckbox,
    WarningIcon,
    FavorableIcon,
  },
  model: {
    prop: 'filteredTechnologies',
    event: 'change',
  },
  props: {
    /*
     * An array of technologies that can be selected
     */
    technologies: {
      type: Array,
      required: true,
    },
    /*
     * The technologies that are selected to filter by
     */
    filteredTechnologies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTechnologies: [...this.filteredTechnologies],
    };
  },
  computed: {
    favorableTech() {
      return this.technologies.filter((tech) => tech.classification === 'favorable');
    },
    unfavorableTech() {
      return this.technologies.filter((tech) => tech.classification === 'unfavorable');
    },
    otherTech() {
      return this.technologies.filter((tech) => tech.classification === 'conventional');
    },
  },
  methods: {
    distinctTechnology(technologyList) {
      const result = [];
      const map = new Map();

      for (const item of technologyList) {  // eslint-disable-line
        if (!map.has(item.description)) {
          map.set(item.description, true);
          result.push({
            ...item,
          });
        }
      }

      return result;
    },
  },
  watch: {
    selectedTechnologies(value) {
      this.$emit('change', value);
    },
    filteredTechnologies() {
      this.selectedTechnologies = this.filteredTechnologies;
    },
  },
};
</script>
