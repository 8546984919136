<template>
<div>
  <div
    class="
      modal-wrapper absolute top-0 left-0 w-screen z-50 h-screen overflow-y-scroll"
    ref="wrapper">
    <div @click="close"
         class="fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-75 -z-1"></div>
    <div class="modal border border-gray-600 flex w-full sm:w-4/5 bg-gray-900 flex flex-col
                mt-6 mb-12 mx-auto">
      <div class="header">
        <slot name="header"/>
      </div>
      <div class="body px-6 pt-4 pb-6">
        <slot name="body" />
      </div>
    </div>
  </div>
</div>
</template>
<script>
import ModalMixin from '@/mixins/ModalMixin';

export default {
  name: 'Modal',
  mixins: [ModalMixin],
};
</script>
