<template>
  <div class="radio">
    <input
      @change="updateValue"
      :id="id"
      type="radio"
      ref="radio"
      class="radio-input"
      :name="name"
      :checked="isChecked"
      :disabled="disabled"/>
    <label
      class="radio-label"
      :for="id">
      <span class="inline-block transform -skew-x-20">{{ label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'RadioButton',
  computed: {
    isChecked() {
      return this.checked === this.value;
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
    /**
     * The group name for the radio button
     */
    name: {
      type: String,
    },
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value);
    },
  },
};
</script>
<style scoped>
  .radio {
    @apply transform skew-x-20;
  }

  .radio-input {
    @apply absolute z-10 m-0 opacity-0 w-0 h-0 top-0 left-0;
  }

  .radio-label {
    @apply inline-block border cursor-pointer text-gray-300 bg-gray-800
     uppercase text-xs font-semibold tracking-widest px-3 py-1
     transition-colors duration-150 ease-linear;
  }

  .radio-input:checked + .radio-label {
    @apply border-blue-100 bg-blue-800 shadow-glowblue100 text-white;
  }

  /* Disabled styles */
  .radio-input:disabled {
    @apply cursor-default;
  }

  .radio-input:disabled + .radio-label {
    @apply cursor-default;
  }

  .radio-input:disabled:checked + .radio-label {
    @apply text-gray-400;
  }
</style>
