<template>
  <div class="bg-gray-900 border border-gray-600 print:bg-white print:border-gray-200">
    <h3 class="font-semibold uppercase text-gray-300 text-sm p-4">
      Filter by Finding
    </h3>
    <ul v-if="Object.keys(parsedFindings).length"
        class="pb-2">
      <li v-for="(finding, index) in parsedFindings"
          :key="`finding-filter-${index}`">
        <FilterCheckbox :id="`finding-checkbox-${index}`"
                        color="yellow"
                        :value="finding.outcome"
                        :label="finding.outcome"
                        :count="finding.count"
                        v-model="selectedFindings"/>
      </li>
    </ul>
    <div v-else
         class="px-4 pt-4 pb-8 text-center">
      <p class="uppercase font-medium tracking-widest text- text-gray-200">
        No Findings to Filter
      </p>
    </div>
  </div>
</template>

<script>
import FilterCheckbox from '@/components/FilterCheckbox.vue';
import constants from '@/config/constants';

export default {
  name: 'FilterFinding',
  components: {
    FilterCheckbox,
  },
  model: {
    prop: 'filteredFindings',
    event: 'change',
  },
  props: {
    /*
     * An array of findings that can be selected
     */
    findings: {
      type: Array,
      required: true,
    },
    /*
     * The findings that are selected to filter by
     */
    filteredFindings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedFindings: [...this.filteredFindings],
      CONST: { ...constants },
    };
  },
  computed: {
    parsedFindings() {
      const parsedFindings = {};
      const { GENERIC } = this.CONST.findings;

      if (this.findings) {
        this.findings.filter((finding) => finding.source !== GENERIC).forEach((finding) => {
          if (parsedFindings[finding.rule.id] === undefined) {
            parsedFindings[finding.rule.id] = {};
          }

          if (parsedFindings[finding.rule.id].count === undefined) {
            parsedFindings[finding.rule.id].count = 1;
          } else {
            const { count } = parsedFindings[finding.rule.id];
            parsedFindings[finding.rule.id].count = count + 1;
          }
          if (parsedFindings[finding.rule.id].outcome === undefined) {
            parsedFindings[finding.rule.id].outcome = finding.rule.outcome;
          }
          if (parsedFindings[finding.rule.id].source === undefined) {
            parsedFindings[finding.rule.id].source = finding.source;
          }
        });
      }

      return parsedFindings;
    },
  },
  watch: {
    selectedFindings(value) {
      this.$emit('change', value);
    },
    filteredFindings() {
      this.selectedFindings = this.filteredFindings;
    },
  },
};
</script>
