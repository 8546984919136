<template>
  <Modal @close="close()">
    <template v-slot:header>
      <div class="left-0 right-0 flex justify-center items-center mb-6 mt-2">
        <div class="
          border-grey-100 border-2 bg-gray-900 py-1 px-5 transform skew-x-20
        ">
          <h2 class="text-white uppercase text-2xl font-medium transform -skew-x-20">
            Delete Report
          </h2>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex items-center justify-center my-4">
        <p>{{ content }}</p>
      </div>
      <div class="flex items-center justify-center my-4">
        <Button type="secondary"
                class="py-2 text-gray-300 mx-6"
                @onClick="close()">
          Cancel
        </Button>
        <Button
          @onClick='handleDelete'
          :inFlight="reportDeleteIsLoading"
          type="primary"
                class="py-2 text-gray-300 mx-6">
          Delete
        </Button>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';
import ModalMixin from '@/mixins/ModalMixin';

export default {
  name: 'DeleteReportModal',
  components: {
    Button,
    Modal,
  },
  computed: {
    ...mapGetters([
      'reportDeleteIsLoading',
    ]),
  },
  methods: {
    ...mapActions([
      'deleteReport',
    ]),
    handleDelete() {
      this.deleteReport(this.reportId)
        .then(() => this.close('new-report'));
    },
  },
  mixins: [ModalMixin],
  props: {
    content: {
      type: String,
      default: 'Are you sure you want to delete this report?',
    },
    reportId: {
      required: true,
    },
  },
};
</script>
