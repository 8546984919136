<template>
  <div class="bg-gray-800 border border-gray-600
              print:bg-white print:border-0">
    <div v-if="isNew"
         class="flex items-center ml-4 mt-4">
      <div class="px-3 py-1 border border-blue-100 bg-blue-800 shadow-glowblue100
                  transform skew-x-20">
        <p class="uppercase text-xs font-semibold text-white tracking-widest
                  transform -skew-x-20">
          New
        </p>
      </div>
      <DashedLine class="hidden sm:block text-gray-300" />
      <div class="hidden sm:block px-3 py-1 border border-gray-600 bg-gray-800
                  transform skew-x-20">
        <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest
                  transform -skew-x-20">
          Not detected in previous orbit
        </p>
      </div>
    </div>
    <div v-if="isResolved"
         class="flex items-center ml-4 mt-4">
      <div class="px-3 py-1 border border-green-500 bg-green-700 shadow-glowgreen
                  transform skew-x-20">
        <p class="uppercase text-xs font-semibold text-white tracking-widest
                  transform -skew-x-20">
          Resolved
        </p>
      </div>
      <DashedLine class="hidden sm:block text-gray-300" />
      <div class="hidden sm:block px-3 py-1 border border-gray-600 bg-gray-800
                  transform skew-x-20">
        <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest
                  transform -skew-x-20">
          Not detected in this orbit
        </p>
      </div>
    </div>
    <!-- Desktop View -->
    <div class="hidden flex-col p-4 print:flex print:py-2"
         :class="[
           {'lg:hidden': rules.length > 4},
           {'lg:flex': rules.length <=4},
         ]">
      <div class="w-full flex">
        <template v-for="(_, index) in rules">
          <h3 class="uppercase text-xs font-semibold text-gray-200 tracking-widest ml-2 mb-2
                     print:text-gray-300"
              :class="[
                {'w-1/5': rules.length === 4},
                {'w-1/4': rules.length === 3},
                {'w-1/3': rules.length === 2},
                {'w-1/2': rules.length === 1},
              ]"
              :key="`desktop-finding-h3-${index}`">
            Finding {{ index + 1 }}
          </h3>
          <div class="px-4"
               :key="`desktop-finding-spacer-${index}`"></div>
        </template>
        <h3 class="uppercase text-xs font-semibold tracking-widest ml-2 mb-2 print:text-orange-400"
            :class="[
              {'text-gray-200': isResolved},
              {'text-yellow-500': !isResolved},
              {'w-1/5': rules.length === 4},
              {'w-1/4': rules.length === 3},
              {'w-1/3': rules.length === 2},
              {'w-1/2': rules.length === 1},
            ]">
          Outcome
        </h3>
      </div>
      <div class="w-full flex">
        <template v-for="(_, index) in rules">
          <div class="flex justify-center items-center p-6 border border-gray-600
                      print:border-b-0 print:border-l-0 print:border-r-0 print:border-gray-100
                      print:p-2 print:text-gray-700 print:justify-start"
               :class="[
                 {'w-1/5': rules.length === 4},
                 {'w-1/4': rules.length === 3},
                 {'w-1/3': rules.length === 2},
                 {'w-1/2': rules.length === 1},
                ]"
               :key="`desktop-rule-${index}`">
            <p class="text-center print:text-left">
              {{ rules[index].outcome }}
            </p>
          </div>
          <div class="flex justify-center items-center flex-shrink-0 px-2
                      print:border-solid print:border-t print:border-gray-100"
               :key="`desktop-arrow-${index}`">
            <ArrowDashed class="print:text-orange-400"
                         :class="[
                            {'text-yellow-500': !isResolved},
                            {'text-gray-200': isResolved},
                          ]"/>
          </div>
        </template>
        <div class="flex justify-center items-center relative p-6
                    print:border-solid print:border-t print:border-gray-100 print:p-2
                    print:text-gray-700 print:justify-start"
             :class="[
               {'scanlines--yellow bg-yellow-900': !isResolved},
               {'scanlines--gray': isResolved},
               {'w-1/5': rules.length === 4},
               {'w-1/4': rules.length === 3},
               {'w-1/3': rules.length === 2},
               {'w-1/2': rules.length === 1},
             ]">
          <div class="outcome-corners print:hidden"
                :class="[
                  {'outcome-corners--yellow': !isResolved},
                  {'outcome-corners--gray': isResolved},
                ]"></div>
          <div class="outcome-border-lr print:hidden"
               :class="[
                  {'outcome-border-lr--yellow': !isResolved},
                  {'outcome-border-lr--gray': isResolved},
                ]"></div>
          <div class="outcome-border-tb print:hidden"
               :class="[
                  {'outcome-border-tb--yellow': !isResolved},
                  {'outcome-border-tb--gray': isResolved},
                ]"></div>
          <p class="text-center print:text-left print:text-black print:font-semibold">
            {{ outcome }}
          </p>
        </div>
      </div>
      <div class="w-full flex">
        <template v-if="expanded">
          <template v-for="(_, ruleIndex) in rules">
            <div class="mt-4 ml-2"
                 :class="[
                   {'w-1/5': rules.length === 4},
                   {'w-1/4': rules.length === 3},
                   {'w-1/3': rules.length === 2},
                   {'w-1/2': rules.length === 1},
                 ]"
                 :key="`desktop-domain-${ruleIndex}`">
              <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest mb-1">
                FOUND ON:
              </p>
              <ul>
                <li v-for="(domainInfo, domain, index) in findingDomainList[ruleIndex]"
                    :key="`finding${ruleIndex}-domain-${index}`">
                  <a
                    @click="handleFoundOnClick(domainInfo.id,
                    rules[ruleIndex].outcome,
                    domainInfo.source)"
                    class="inline-block uppercase text-xs font-semibold text-gray-100
                            tracking-widest group outline-none cursor-pointer
                            hover:text-white focus:text-white
                            transition-colors duration-150 linear">
                    {{ domain }}
                    <ArrowRight class="inline-block text-gray-300 transform transform -translate-x-1
                                       opacity-0
                                       group-hover:opacity-100 group-focus:opacity-100
                                       group-hover:translate-x-0 group-focus:translate-x-0
                                       transition duration-150 ease-in-out" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="px-4" :key="`desktop-domain-padding-${ruleIndex}`"></div>
          </template>
          <div class="mt-4 ml-2"
               :class="[
                 {'w-1/5': rules.length === 4},
                 {'w-1/4': rules.length === 3},
                 {'w-1/3': rules.length === 2},
                 {'w-1/2': rules.length === 1},
               ]">
            <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest mb-1">
              FOUND ON:
            </p>
            <ul>
              <li v-for="(domainInfo, domain, index) in outcomeDomainList"
                  :key="`outcome-domain-${index}`">
                <a
                  @click="handleFoundOnClick(domainInfo.id, '' , domainInfo.source)"
                   class="inline-block uppercase text-xs font-semibold text-gray-100 tracking-widest
                          group outline-none cursor-pointer
                          hover:text-white focus:text-white
                          transition-colors duration-150 linear">
                  {{ domain }}
                  <ArrowRight class="inline-block text-gray-300 transform transform -translate-x-1
                                     opacity-0
                                     group-hover:opacity-100 group-focus:opacity-100
                                     group-hover:translate-x-0 group-focus:translate-x-0
                                     transition duration-150 ease-in-out" />
                </a>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <!-- End Desktop View -->
    <!-- Mobile View -->
    <div class="flex flex-col p-4 print:hidden"
         :class="[
           {'lg:flex': rules.length > 4},
           {'lg:hidden': rules.length <=4},
         ]">
      <template v-for="(_, ruleIndex) in rules">
        <div class="w-full flex flex-col"
             :key="`mobile-rule-${ruleIndex}`">
          <h3 class="uppercase text-xs font-semibold text-gray-200 tracking-widest ml-2 mb-2">
            Finding {{ ruleIndex + 1 }}
          </h3>
          <div class="p-6 border border-gray-600">
            <p class="text-center">
              {{ rules[ruleIndex].outcome }}
            </p>
          </div>
          <div v-if="expanded"
               class="border border-t-0 border-gray-600 lg:border-0">
            <div class="p-4 lg:p-0 lg:mt-4 lg:ml-2">
              <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest mb-1">
                FOUND ON:
              </p>
              <ul>
                <li v-for="(domainInfo, domain, index) in findingDomainList[ruleIndex]"
                    :key="`finding${ruleIndex}-domain-${index}`">
                  <a
                    @click="handleFoundOnClick(domainInfo.id,
                      rules[ruleIndex].outcome,
                      domainInfo.source)"
                     class="inline-block uppercase text-xs font-semibold text-gray-100
                            tracking-widest group outline-none cursor-pointer
                            hover:text-white focus:text-white
                            transition-colors duration-150 linear">
                    {{ domain }}
                    <ArrowRight class="inline-block text-gray-300 transform transform -translate-x-1
                                       opacity-0
                                       group-hover:opacity-100 group-focus:opacity-100
                                       group-hover:translate-x-0 group-focus:translate-x-0
                                       transition duration-150 ease-in-out" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center flex-shrink-0 px-2 mt-6 mb-2"
             :key="`mobile-arrow-${ruleIndex}`">
          <ArrowDashed class="transform rotate-90"
                       :class="[
                            {'text-yellow-500': !isResolved},
                            {'text-gray-200': isResolved},
                          ]"/>
        </div>
      </template>
      <div class="w-full flex flex-col">
        <h3 class="uppercase text-xs font-semibold tracking-widest ml-2 mb-2"
            :class="[
              {'text-gray-200': isResolved},
              {'text-yellow-500': !isResolved},
            ]">
          Outcome
        </h3>
        <div class="relative p-6"
             :class="[
               {'scanlines--yellow bg-yellow-900': !isResolved},
               {'scanlines--gray': isResolved},
             ]">
          <div class="outcome-corners"
               :class="[
                  {'outcome-corners--yellow': !isResolved},
                  {'outcome-corners--gray': isResolved},
                ]"></div>
          <div class="outcome-border-lr"
               :class="[
                  {'outcome-border-lr--yellow': !isResolved},
                  {'outcome-border-lr--gray': isResolved},
                ]"></div>
          <div class="outcome-border-tb"
               :class="[
                  {'outcome-border-tb--yellow': !isResolved},
                  {'outcome-border-tb--gray': isResolved},
                ]"></div>
          <p class="text-center">
            {{ outcome }}
          </p>
        </div>
        <div v-if="expanded"
             class="border border-t-0 border-gray-600 lg:border-0">
          <div class="p-4 lg:p-0 lg:mt-4 lg:ml-2">
            <p class="uppercase text-xs font-semibold text-gray-200 tracking-widest mb-1">
              FOUND ON:
            </p>
            <ul>
              <li v-for="(domainInfo, domain, index) in outcomeDomainList"
                  :key="`outcome-domain-${index}`">
                <a
                   @click="handleFoundOnClick(domainInfo.id, '' , domainInfo.source)"
                   class="inline-block uppercase text-xs font-semibold text-gray-100 tracking-widest
                          group outline-none cursor-pointer
                          hover:text-white focus:text-white
                          transition-colors duration-150 linear">
                  {{ domain }}
                  <ArrowRight class="inline-block text-gray-300 transform transform -translate-x-1
                                     opacity-0
                                     group-hover:opacity-100 group-focus:opacity-100
                                     group-hover:translate-x-0 group-focus:translate-x-0
                                     transition duration-150 ease-in-out" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Mobile View -->
    <div class="border-t border-gray-600 print:hidden">
      <Button type="text-small"
              class="w-full py-2 text-gray-300"
              @onClick="expanded = !expanded">
        {{ expanded ? 'Collapse' : 'Expand' }}
        <template v-slot:iconRight>
          <Chevron class="text-gray-200 transform
                            transition-transform duration-200 linear"
                   :class="{'-rotate-180': expanded}"/>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Chevron from '@/assets/chevron.svg?inline';
import ArrowRight from '@/assets/arrow_right.svg?inline';
import ArrowDashed from '@/assets/arrow_dashed.svg?inline';
import DashedLine from '@/assets/line_dashed.svg?inline';

export default {
  name: 'AttackPathway',
  components: {
    Button,
    Chevron,
    ArrowRight,
    ArrowDashed,
    DashedLine,
  },
  props: {
    /*
     Determines whether to change the styling of the component to the "resolved" styling.
     Shows "resolved" emblem, changes arrows to gray, changes outcome text and box to gray
     */
    isResolved: {
      type: Boolean,
      default: false,
    },
    /*
     The sentence that will be displayed in the "outcome" box
     */
    outcome: {
      type: String,
      required: true,
    },
    /*
     An array of finding rules that will be displayed in the boxes before the outcome box
     */
    rules: {
      type: Array,
      required: true,
    },
    /*
     An array of the total findings for the report, which will be parsed to find the domains
     */
    findings: {
      type: Array,
      required: true,
    },
    /*
     An array of the newly added AttackPathways, to determine if the "New" emblem should be shown
     */
    newAttackPathways: {
      type: Array,
      default: () => ([]),
    },
    /*
      The domain of the report
      (If the findings domain array is not received from the backend, then the parent input
      is the only domain.)
     */
    reportDomain: {
      type: String,
      default: '',
    },
    /*
      A list of domains, so report ids can be retrieved.
     */
    domains: {
      type: Array,
      required: true,
    },
    /*
      The ID of the executive report, used for linking to Domain Report in Found On section.
     */
    executiveReportID: {
      type: String,
      required: true,
    },
    /*
      Domain ID used for "Found On" link. Used for Attack Pathways on the Domain Reports.
     */
    domainId: {
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
      findingDomainList: {},
      outcomeDomainList: {},
    };
  },
  mounted() {
    if (this.domains.length > 0 || this.domainId) {
      this.populateFindingDomainList();
      this.populateOutcomeDomainList();
    }
  },
  methods: {
    getDomainId(foundDomain) {
      let id = '';
      this.domains.forEach((domain) => {
        if (domain.name === foundDomain) {
          id = domain.id;
        }
      });
      return id;
    },
    populateFindingDomainList() {
      this.findingDomainList = {};

      this.rules.forEach((_, rulesIndex) => {
        const { id } = this.rules[rulesIndex];
        const domains = {};

        this.findings.forEach((finding) => {
          if (finding.rule.id === id) {
            if (finding.domains) {
              finding.domains.forEach((domain) => {
                domains[domain.domain] = {
                  id: this.domainId || this.getDomainId(domain.domain),
                  source: finding.source,
                };
              });
            } else {
              domains[this.reportDomain] = {
                id: this.domainId || this.getDomainId(this.reportDomain),
                source: finding.source,
              };
            }
            this.findingDomainList[rulesIndex] = { ...domains };
          }
        });
      });
    },
    populateOutcomeDomainList() {
      this.outcomeDomainList = {};
      const domainList = [];
      const domainCount = {};
      Object.keys(this.findingDomainList).forEach((findingKey) => {
        domainList.push(...Object.keys(this.findingDomainList[findingKey]));
      });

      domainList.forEach((domain) => {
        domainCount[domain] = (domainCount[domain] || 0) + 1;
      });

      // If the domain shows up in all of the rules (or more) then we want it to be in the final
      // domain list
      Object.keys(domainCount).forEach((domain) => {
        if (domainCount[domain] >= this.rules.length) {
          Object.keys(this.findingDomainList).forEach((findingIndex) => {
            if (this.findingDomainList[findingIndex][domain]) {
              this.outcomeDomainList[domain] = {
                id: this.findingDomainList[findingIndex][domain].id,
                source: this.findingDomainList[findingIndex][domain].source,
              };
            }
          });
        }
      });
    },
    handleFoundOnClick(id, finding, source) {
      this.$router.push({
        name: 'domain-report',
        params: {
          execReport: this.executiveReportID,
          domainReport: id,
        },
      })
        .catch(() => {});

      if (finding && source !== 'generic') {
        this.$root.$emit('findingsFilter', finding);
      }
    },
  },
  computed: {
    isNew() {
      return this.newAttackPathways.some((addedPathway) => (
        addedPathway.attackPathwayRule.outcome === this.outcome
        && addedPathway.attackPathwayRule.rules
          .every((rule, index) => rule.id === this.rules[index].id)));
    },
  },
  watch: {
    domains() {
      this.populateFindingDomainList();
      this.populateOutcomeDomainList();
    },
  },
};
</script>

<style scoped>
.scanlines--yellow {
  background:
    radial-gradient(#251F1C 60%, transparent 90%),
    repeating-linear-gradient(
      0deg,
      #251F1C,
      #251F1C 5px,
      rgba(249, 194, 46, 0.1) 5px,
      rgba(249, 194, 46, 0.1) 6px);
  background-position: center;
  background-size: 100% 110%;
}

.scanlines--gray {
  background:
    radial-gradient(#251F1C 60%, transparent 90%),
    repeating-linear-gradient(
      0deg,
      #251F1C,
      #251F1C 5px,
      rgba(181, 177, 174, 0.1) 5px,
      rgba(181, 177, 174, 0.1) 6px);
  background-position: center;
  background-size: 100% 110%;
}

@screen print {
  .scanlines--yellow, .scanlines--gray {
    background: white;
  }
}

.outcome-corners {
  @apply absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.outcome-corners--yellow {
  background:
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.outcome-corners--gray {
  background:
    linear-gradient(to right, rgba(181, 177, 174, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to right, rgba(181, 177, 174, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to left, rgba(181, 177, 174, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to left, rgba(181, 177, 174, 1) 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, rgba(181, 177, 174, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, rgba(181, 177, 174, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to top, rgba(181, 177, 174, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to top, rgba(181, 177, 174, 1) 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.outcome-border-tb {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.outcome-border-tb--yellow {
  background:
    linear-gradient(to right, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 0,
    linear-gradient(to left, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 0,
    linear-gradient(to right, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 100%,
    linear-gradient(to left, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50% 2px;
}

.outcome-border-tb--gray {
  background:
    linear-gradient(to right, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 0 0,
    linear-gradient(to left, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 100% 0,
    linear-gradient(to right, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 0 100%,
    linear-gradient(to left, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50% 2px;
}

.outcome-border-lr {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.outcome-border-lr--yellow {
  background:
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 0,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 0,
    linear-gradient(to top, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 0 100%,
    linear-gradient(to top, rgba(249, 194, 46, 1) 0%, rgba(249, 194, 46, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 2px 50%;
}

.outcome-border-lr--gray {
  background:
    linear-gradient(to bottom, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 0 0,
    linear-gradient(to bottom, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 100% 0,
    linear-gradient(to top, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 0 100%,
    linear-gradient(to top, rgba(181, 177, 174, 1) 0%, rgba(181, 177, 174, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 2px 50%;
}
</style>
