<template>
  <div class="relative flex flex-col justify-center w-full
              bg-gray-900 border border-gray-600
              print:bg-white"
      :class="[
        {'print:border-orange-400': hasAttackPathways},
        {'print:border-green-500': !hasAttackPathways},
      ]">
    <div class="attack-pathways-header" :class="[
        {'attack-pathways-header--inset': hasInsetHeader},
      ]">
      <div class="warning-bar h-8 sm:h-12 print:hidden"
           :class="[
                   {'warning-bar--green': !hasAttackPathways},
                   {'warning-bar--yellow': hasAttackPathways}
                 ]">
        <div class="warning-bar-inner"
             :class="[
                   {'warning-bar-inner--green': !hasAttackPathways},
                   {'warning-bar-inner--yellow': hasAttackPathways}
                 ]"></div>
        <div class="warning-bar-corners"
             :class="[
                   {'warning-bar-corners--green': !hasAttackPathways},
                   {'warning-bar-corners--yellow': hasAttackPathways}
                 ]"></div>
      </div>
      <div class="inline-block px-6 sm:px-12 py-3 border
                  transform -skew-x-40
                  print:bg-white"
           :class="[
                 {'bg-green-900 border-green-500': !hasAttackPathways},
                 {'bg-yellow-900 border-yellow-500 print:border-orange-400': hasAttackPathways}
               ]">
        <h1 class="inline-block uppercase font-medium text-lg sm:text-2xl transform skew-x-40
                   print:tracking-widest print:text-black print:font-semibold">
          Attack Pathways [{{ attackPathwaysCount }}]
        </h1>
      </div>
    </div>
    <div v-if="!hasAttackPathways"
         class="px-12 py-24 text-center mt-6 print:py-8">
      <h2 class="uppercase font-medium tracking-widest text-lg">
        No Attack Pathways Discovered
      </h2>
      <p class="text-gray-200">
        Great job! Our orbital reconnaissance did not uncover any attack pathways.
      </p>
    </div>
    <div v-else
         class="mt-6">
      <p class="hidden text-gray-700 mx-auto italic text-center my-2
                print:block">
        Based on our findings, these are the steps an attacker could take against you.
      </p>
      <div class="p-6 print:p-0">
        <slot name="pathways"></slot>
      </div>
      <div v-if="isShowingPrevious && hasResolvedAttackPathways"
           class="w-full border-t border-gray-600 px-6 pt-6 mt-4">
        <div class="flex -mt-12 w-full justify-center">
          <h2 class="relative inline-flex uppercase px-4 py-1 bg-gray-900
                 border border-gray-600 transform skew-x-20">
                <span class="relative inline-block z-10 text-2xl transform -skew-x-20">
                  Resolved Pathways [{{resolvedAttackPathwaysCount}}]
                </span>
          </h2>
        </div>
        <div v-if="showResolvedPathways" class="mt-4">
          <slot name="resolvedPathways"></slot>
        </div>
        <Button type="text-small"
                class="w-full py-4 text-gray-300"
                @onClick="showResolvedPathways = !showResolvedPathways">
          {{ showResolvedPathways ? 'Hide' : 'Show' }}
          <template v-slot:iconRight>
            <Chevron class="text-gray-200 transform
                            transition-transform duration-200 linear"
                     :class="{'-rotate-180': showResolvedPathways}"/>
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Chevron from '@/assets/chevron.svg?inline';

export default {
  name: 'AttackPathwaysWrapper',
  props: {
    /*
     * Determines what number to put in the header for the Attack Pathways
     */
    attackPathwaysCount: {
      type: Number,
      required: true,
    },
    /*
     * Determines whether the Resolved Pathways section will be seen
     */
    isShowingPrevious: {
      type: Boolean,
      required: true,
    },
    /*
     * Determines what number to put in the header for the Resolved Attack Pathways
     */
    resolvedAttackPathwaysCount: {
      type: Number,
      required: true,
    },
    /*
     * Determines whether the attack pathways header is displayed as inset or overflows
     */
    hasInsetHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Chevron,
  },
  data() {
    return {
      showResolvedPathways: true,
    };
  },
  computed: {
    hasAttackPathways() {
      return this.attackPathwaysCount;
    },
    hasResolvedAttackPathways() {
      return this.resolvedAttackPathwaysCount;
    },
  },
};
</script>

<style scoped>
.attack-pathways-header {
  @apply absolute flex justify-center;
  top: -1.5rem;
  left: -0.5rem;
  width: calc(100% + 1rem);
}

.attack-pathways-header--inset {
  top: -2rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
}

.warning-bar {
  @apply absolute left-0 w-full bg-yellow-900 border border-yellow-500;
  top: 0.35rem;
}

.warning-bar--yellow {
  @apply bg-yellow-900 border-yellow-500;
}

.warning-bar--green {
  @apply bg-green-900 border-green-500;
}

.warning-bar-inner {
  @apply absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}

.warning-bar-inner--yellow {
  @apply bg-yellow-500;
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 100% 88px;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(-50deg,
  transparent,
  transparent 5px,
  rgba(249, 194, 46, 0.25) 10px,
  rgba(249, 194, 46, 1) 11px,
  rgba(249, 194, 46, 1) 22px,
  rgba(249, 194, 46, 0.25) 23px,
  transparent 28px);
}

.warning-bar-inner--green {
  @apply bg-green-500;
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 100% 88px;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(-50deg,
  transparent,
  transparent 5px,
  rgba(59, 178, 115, 0.25) 10px,
  rgba(59, 178, 115, 1) 11px,
  rgba(59, 178, 115, 1) 22px,
  rgba(59, 178, 115, 0.25) 23px,
  transparent 28px);
}

.warning-bar-corners {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.warning-bar-corners--yellow {
  background:
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to right, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to left, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to top, rgba(249, 194, 46, 1) 3px, transparent 3px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.warning-bar-corners--green {
  background:
    linear-gradient(to right, #3BB273 3px, transparent 3px) 0 0,
    linear-gradient(to right, #3BB273 3px, transparent 3px) 0 100%,
    linear-gradient(to left, #3BB273 3px, transparent 3px) 100% 0,
    linear-gradient(to left, #3BB273 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, #3BB273 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, #3BB273 3px, transparent 3px) 100% 0,
    linear-gradient(to top, #3BB273 3px, transparent 3px) 0 100%,
    linear-gradient(to top, #3BB273 3px, transparent 3px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;
}
</style>
