<template>
  <button
    @click.stop="updateVisibility"
    class="rectangle button"
    :class="[
      { 'rectangle--yellow': visible },
      { 'opacity-50': inFlight },
      { 'opacity-100': !inFlight },
    ]"
    :disabled="inFlight">
    {{ label }}
    <template v-if="ipAddress">
      <span class="px-1 bg-gray-700 ml-2 tracking-wider text-xs uppercase">
        {{ ipAddress }}
      </span>
    </template>
    <div class="text-white inline-flex items-center ml-2" v-if="visible">
      <VisibleIcon class="mr-1" />
      <span class="tracking-wider text-xs uppercase">
        Visible
      </span>
    </div>
    <div class="text-gray-300 inline-flex items-center ml-2" v-else>
      <HiddenIcon class="mr-1" />
      <span class="tracking-wider text-xs uppercase">
        Hidden
      </span>
    </div>
  </button>
</template>
<script>
import HiddenIcon from '@/assets/hidden.svg?inline';
import VisibleIcon from '@/assets/visible.svg?inline';

export default {
  name: 'VisibilityToggle',
  components: {
    HiddenIcon,
    VisibleIcon,
  },
  props: {
    /**
     * The id for the
     */
    id: {
      type: String,
    },
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Determines which visibility icon, text, and rectangle color to show on the button
     */
    visible: {
      type: Boolean,
      required: true,
    },
    /**
     * An IP address that's associated with a finding
     */
    ipAddress: {
      type: String,
    },
    /*
     * Determines whether to display the loading state of the toggle
     */
    inFlight: {
      type: Boolean,
    },
    /*
     * The action that will be called when the toggle is pressed
     */
    action: {
      type: Function,
      required: true,
    },
    /*
     * The collection that will be altered in state when visibility is changed
     */
    collection: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateVisibility() {
      this.$emit('onClick', this.id, !this.visible, this.action, this.collection);
    },
  },
};
</script>
<style scoped>
.button {
  @apply flex items-center relative pl-3 pr-2 py-1 cursor-pointer w-full outline-none
  transition duration-150 ease-linear;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  &:hover {
    @apply bg-yellow-900;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rectangle::after {
  @apply absolute left-0 bg-gray-300 mt-1
         transition-opacity duration-100 ease-linear;
  top: 0.35rem;
  width: 2px;
  height: .75rem;
  content: '';
}

.rectangle--yellow::after {
  @apply bg-yellow-500 shadow-glowyellow;
}
</style>
