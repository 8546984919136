<template>
  <div class="flex flex-col border border-gray-600 bg-gray-900
              print:bg-white print:border-gray-200">
    <div class="-mt-4 ml-3 mb-2">
      <h2 class="triangle relative inline-flex uppercase pl-2 py-1 bg-gray-900
                 border-t border-l border-b border-gray-600
                 print:bg-white print:border-gray-200">
        <span class="relative inline-block z-10
                     print:tracking-widest print:text-black print:font-semibold">Tech Stack</span>
      </h2>
    </div>
    <div class="grid grid-flow-col px-4 pb-4">
      <div class="flex flex-col row-start-1 col-start-1 mb-2">
        <span class="text-3xl">{{ externalHosts }}</span>
        <span class="uppercase text-gray-300 text-xs font-semibold tracking-widest
                     print:text-gray-300">
          External {{ 'Host' | pluralize(externalHosts) }}
        </span>
      </div>
      <div class="flex flex-col row-start-1 col-start-2 mb-2">
        <span class="text-3xl">{{ internalHosts }}</span>
        <span class="uppercase text-gray-300 text-xs font-semibold tracking-widest">
          Internal {{ 'Host' | pluralize(internalHosts) }}
        </span>
      </div>
      <!-- Print Version -->
      <div class="hidden flex-col row-start-1 col-start-3
                  print:flex">
        <span class="text-3xl">{{ domainCount }}</span>
        <span class="uppercase text-gray-300 text-xs font-semibold tracking-widest">
          {{ 'Domain' | pluralize(domainCount) }}
        </span>
      </div>
      <!-- End Print Version -->
      <div class="row-start-2 col-start-1">
        <div class="flex items-center">
          <Warning class="mr-1 print:hidden" />
          <WarningPrint class="mr-1 w-6 h-6 hidden print:inline-block" />
          <span class="uppercase text-yellow-500 text-xs font-semibold">Unfavorable Tech</span>
        </div>
        <ul>
          <li v-for="(tech, index) in unfavorableTech"
              :key="`unfavorable-tech-${index}`">
            {{ tech.description }}
            <span
              v-if="tech.semanticVersion"
              v-tooltip="getVersionTooltip(tech.isUpToDate)"
              class="text-xs font-semibold cursor-default"
              :class="getVersionClass(tech.isUpToDate)">
                v{{ tech.semanticVersion }}
            </span>
          </li>
          <template v-if="showPrevious">
            <li v-for="(tech, index) in prevUnfavorableTech"
                :key="`prevUnfavorable-tech-${index}`"
                class="text-gray-300 line-through">
              {{ tech.description }}
              <span
                v-if="tech.semanticVersion"
                class="text-gray-300 text-xs font-semibold cursor-default">
                  v{{ tech.semanticVersion }}
              </span>
            </li>
          </template>
        </ul>
        <p v-if="(!showPrevious
                && unfavorableTech.length === 0)
                || (showPrevious
                && unfavorableTech.length === 0
                && prevUnfavorableTech.length === 0)"
           class="uppercase font-medium tracking-widest text-base text-gray-400">
          N/A
        </p>
      </div>
      <div class="row-start-2 col-start-2">
        <div class="flex items-center">
          <Favorable class="mr-1 print:hidden" />
          <FavorablePrint class="mr-1 hidden print:inline-block" />
          <span class="uppercase text-green-500 text-xs font-semibold">Favorable Tech</span>
        </div>
        <ul>
          <li v-for="(tech, index) in favorableTech"
              :key="`favorable-tech-${index}`">
            {{ tech.description }}
            <span
              v-if="tech.semanticVersion"
              v-tooltip="getVersionTooltip(tech.isUpToDate)"
              class="text-xs font-semibold cursor-default"
              :class="getVersionClass(tech.isUpToDate)">
                v{{ tech.semanticVersion }}
            </span>
          </li>
          <template v-if="showPrevious">
            <li v-for="(tech, index) in prevFavorableTech"
                :key="`prevFavorable-tech-${index}`"
                class="text-gray-300 line-through">
              {{ tech.description }}
              <span
                v-if="tech.semanticVersion"
                class="text-green-500 text-xs font-semibold cursor-default">
                v{{ tech.semanticVersion }}
              </span>
            </li>
          </template>
        </ul>
        <p v-if="(!showPrevious
                && favorableTech.length === 0)
                || (showPrevious
                && favorableTech.length === 0
                && prevFavorableTech.length === 0)"
           class="uppercase font-medium tracking-widest text-base text-gray-400">
          N/A
        </p>
      </div>
      <div class="row-start-3 col-start-1
                  print:row-start-2 print:col-start-3"
          :class="[
             {'lg:row-start-2 lg:col-start-3': !isVertical},
           ]">
        <span class="uppercase text-gray-300 text-xs font-semibold">Other Tech</span>
        <ul>
          <li v-for="(tech, index) in otherTech"
              :key="`other-tech-${index}`">
            {{ tech.description }}
            <span
              v-if="tech.semanticVersion"
              v-tooltip="getVersionTooltip(tech.isUpToDate)"
              class="text-xs font-semibold cursor-default"
              :class="getVersionClass(tech.isUpToDate)">
                v{{ tech.semanticVersion }}
            </span>
          </li>
          <template v-if="showPrevious">
            <li v-for="(tech, index) in prevOtherTech"
                :key="`prevOther-tech-${index}`"
                class="text-gray-300 line-through">
              {{ tech.description }}
              <span
                v-if="tech.semanticVersion"
                class="text-gray-300 text-xs font-semibold cursor-default">
                v{{ tech.semanticVersion }}
              </span>
            </li>
          </template>
        </ul>
        <p v-if="(!showPrevious
                && otherTech.length === 0)
                || (showPrevious
                && otherTech.length === 0
                && prevOtherTech.length === 0)"
           class="uppercase font-medium tracking-widest text-base text-gray-400">
          N/A
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Warning from '@/assets/warning.svg?inline';
import WarningPrint from '@/assets/warning--noglow.svg?inline';
import Favorable from '@/assets/favorable.svg?inline';
import FavorablePrint from '@/assets/favorable--noglow.svg?inline';

export default {
  name: 'Summary',
  props: {
    /*
      Determines whether to display the tech in a vertical layout
     */
    isVertical: {
      type: Boolean,
      default: false,
    },
    /*
      The number of external hosts
     */
    externalHosts: {
      type: Number,
      default: 0,
    },
    /*
      The number of internal hosts
     */
    internalHosts: {
      type: Number,
      default: 0,
    },
    /*
     An array of technologies used
     */
    technologies: {
      type: Array,
      default: () => [],
    },
    /*
     An array of technologies from previous report used
     */
    previousTechnologies: {
      type: Array,
      default: () => [],
    },
    /*
     Determines whether previous tech is show or not
     */
    showPrevious: {
      type: Boolean,
      default: false,
    },
    /*
     The number of Domains. For the Print version of the summary
     */
    domainCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Warning,
    WarningPrint,
    Favorable,
    FavorablePrint,
  },
  methods: {
    sortTechnologies(a, b) {
      // Sorts first by description then version number
      if (a.description < b.description) return -1;
      if (a.description > b.description) return 1;
      if (a.semanticVersion < b.semanticVersion) return -1;
      if (a.semanticVersion > b.semanticVersion) return 1;
      return 0;
    },
    getVersionTooltip(version) {
      if (version === null) return '';
      if (version) return 'Up to date';

      return 'Out of date';
    },
    getVersionClass(version) {
      if (version === null) return 'text-gray-300';
      if (version) return 'text-green-500';

      return 'text-yellow-500';
    },
  },
  computed: {
    favorableTech() {
      return this.technologies.filter((tech) => tech.classification === 'favorable').sort(this.sortTechnologies);
    },
    unfavorableTech() {
      return this.technologies.filter((tech) => tech.classification === 'unfavorable').sort(this.sortTechnologies);
    },
    otherTech() {
      return this.technologies.filter((tech) => tech.classification === 'conventional').sort(this.sortTechnologies);
    },
    prevFavorableTech() {
      return this.previousTechnologies.filter((tech) => tech.classification === 'favorable').sort(this.sortTechnologies);
    },
    prevUnfavorableTech() {
      return this.previousTechnologies.filter((tech) => tech.classification === 'unfavorable').sort(this.sortTechnologies);
    },
    prevOtherTech() {
      return this.previousTechnologies.filter((tech) => tech.classification === 'conventional').sort(this.sortTechnologies);
    },
  },
};
</script>

<style scoped>
.triangle::before {
  @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.5rem);
  width: calc(1rem + 5px);
  height: calc(100% + 2px);
  content: '';
}

@screen print {
  .triangle::before {
    @apply bg-white -skew-x-20 border-gray-200;
  }
}
</style>
