<template>
  <div class="flex flex-col justify-center items-center
              scanlines--red relative px-8 py-12
              sm:px-20">
    <div class="corners corners--red"></div>
    <div class="border-lr border-lr--red"></div>
    <div class="border-tb border-tb--red"></div>
    <h1 class="inline-block uppercase font-semibold text-lg sm:text-3xl text-red-100
                   tracking-widest text-glowRed400
                   print:tracking-widest print:text-black print:font-semibold">
      <slot name="main"></slot>
    </h1>
    <h2 class="inline-block uppercase font-medium text-base sm:text-lg text-red-400
               text-glowRed400 tracking-widest print:text-black print:font-medium">
      <slot name="additional"></slot>
    </h2>
    <div
      v-if="isSuperUser"
      class="
        absolute right-0 delete mr-2 text-gray-300 hover:text-white">
      <Button
        type="text-small"
        @onClick="$emit('showDeleteModal', '')">
        Delete
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/Button.vue';

export default {
  name: 'ReportErrorMessage',
  components: {
    Button,
  },
  computed: {
    ...mapGetters([
      'isSuperUser',
    ]),
  },
};
</script>

<style scoped>
.scanlines--red {
  background:
    radial-gradient(#491c1c 60%, transparent 90%),
    repeating-linear-gradient(
      0deg,
      #491c1c,
      #491c1c 5px,
      rgba(229, 62, 62, 0.4) 5px,
      rgba(229, 62, 62, 0.4) 6px);
  background-position: center;
  background-size: 100% 110%;
}

.corners {
  @apply absolute;
  top: -4px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 8px);
}

.corners--red {
  background:
    linear-gradient(to right, rgba(229, 62, 62, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to right, rgba(229, 62, 62, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to left, rgba(229, 62, 62, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to left, rgba(229, 62, 62, 1) 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, rgba(229, 62, 62, 1) 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, rgba(229, 62, 62, 1) 3px, transparent 3px) 100% 0,
    linear-gradient(to top, rgba(229, 62, 62, 1) 3px, transparent 3px) 0 100%,
    linear-gradient(to top, rgba(229, 62, 62, 1) 3px, transparent 3px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.border-lr {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.border-lr--red {
  background:
    linear-gradient(to bottom, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 0 0,
    linear-gradient(to bottom, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 100% 0,
    linear-gradient(to top, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 0 100%,
    linear-gradient(to top, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 2px 50%;
}

.border-tb {
  @apply absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.border-tb--red {
  background:
    linear-gradient(to right, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 0 0,
    linear-gradient(to left, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 100% 0,
    linear-gradient(to right, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 0 100%,
    linear-gradient(to left, rgba(229, 62, 62, 1) 0%, rgba(229, 62, 62, 0.05) 90%) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50% 2px;
}

.text-glowRed400 {
  text-shadow: #E53E3E 0px 0px 8px;
}

.delete {
  bottom: -32px;
}
</style>
