<template>
  <div class="checkbox-wrapper">
    <input
      @change="updateValue"
      :id="id"
      type="checkbox"
      ref="checkbox"
      class="checkbox-input"
      :checked="isChecked"
      :disabled="disabled"/>
    <label
      class="block checkbox-label noselect"
      :class="[
        {'block--yellow': color === 'yellow'},
        {'block--blue': color === 'blue'},
      ]"
      :for="id">
      <div class="flex flex-grow-0">
        <span class="mr-4 text-gray-200">{{ count || '' }}</span>
        <span>{{ label }}</span>
      </div>
      <CloseIcon class="close-icon opacity-0 flex-grow mt-2 ml-2 text-gray-200
                        transition-opacity duration-100 ease-linear" />
    </label>
  </div>
</template>
<script>
import CloseIcon from '@/assets/cross.svg?inline';

export default {
  name: 'FilterCheckbox',
  components: {
    CloseIcon,
  },
  computed: {
    isChecked() {
      if (Array.isArray(this.checked)) {
        return this.checked.includes(this.value);
      }
      return this.checked;
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The findings count that will display before the label
     */
    count: {
      type: Number,
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
    /*
     * The color used for the background and colored block
     */
    color: {
      type: String,
      validator: (type) => ['yellow', 'blue'].indexOf(type) !== -1,
      default: 'yellow',
    },
  },
  methods: {
    updateValue() {
      if (Array.isArray(this.checked)) {
        const checkedItems = this.checked.slice();
        if (checkedItems.indexOf(this.value) !== -1) {
          checkedItems.splice(checkedItems.indexOf(this.value), 1);
        } else {
          checkedItems.push(this.value);
        }
        this.$emit('change', checkedItems);
      } else {
        this.$emit('change', !this.checked);
      }
    },
  },
};
</script>
<style scoped>
.checkbox-wrapper {
  @apply block relative;
}

.checkbox-input {
  @apply cursor-pointer absolute z-10 m-0 opacity-0 w-0 h-0 top-0 left-0;
}

.checkbox-label {
  @apply flex relative pl-3 mb-2 pr-2 py-1 cursor-pointer
  transition-colors duration-150 ease-linear;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.checkbox-label.block--yellow {
  &:hover {
    @apply bg-yellow-900;
  }
}

.checkbox-label.block--blue {
  &:hover {
    @apply bg-blue-900;
  }
}

.checkbox-input:checked + .checkbox-label.block--yellow {
  @apply bg-yellow-900;
}

.checkbox-input:checked + .checkbox-label.block--blue {
  @apply bg-blue-900;
}

.checkbox-input:checked + .checkbox-label > .close-icon,
.checkbox-input:checked + .block::after {
  @apply opacity-100;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.block::after {
  @apply absolute left-0 bg-yellow-500 mt-1 shadow-glowyellow opacity-0
         transition-opacity duration-100 ease-linear;
  top: 0.35rem;
  width: 2px;
  height: .75rem;
  content: '';
}

.block--yellow::after {
  @apply bg-yellow-500 shadow-glowyellow;
}

.block--blue::after {
  @apply bg-blue-100 shadow-glowblue100;
}
</style>
