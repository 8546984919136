<template>
  <p
    class="
      uppercase text-xs font-semibold cursor-default
      truncate transition duration-150 cursor-pointer
    "
    :class="{ 'text-white': (copying || hover), 'text-gray-300': (!copying && !hover) }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="copyToClipboard"
    v-tooltip="subdomain"
  >
    <span
      v-if='copying'>Copied</span>
    <span
      v-else-if="!hover"
    >
      {{ subdomain }}
    </span>
    <span
      v-else>
      Click to Copy
    </span>
  </p>
</template>
<script>
export default {
  name: 'CopySubdomain',
  data() {
    return {
      copying: false,
      hover: false,
    };
  },
  methods: {
    copyToClipboard() {
      this.copying = !this.copying;

      const el = document.createElement('textarea');
      el.value = this.subdomain; // set content to subdomain
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      // eslint-disable-next-line
      setTimeout(() => this.copying = false, 1500);
    },
  },
  props: {
    subdomain: {
      type: String,
      required: true,
    },
  },
};
</script>
