<template>
  <Modal @close="close(null, hasEditedReport)">
    <template v-slot:header>
      <div class="relative left-0 right-0 flex justify-center items-center mb-6 -mt-6">
        <div class="
          border-gray-600 border bg-gray-900 py-1 px-12 transform skew-x-20
        ">
          <h2 class="text-white uppercase text-2xl font-medium transform -skew-x-20">
            Edit Report
          </h2>
        </div>
        <div class="absolute right-0 mt-4 mr-3">
          <Button type="text-small" @onClick="close(null, hasEditedReport)">
            Close
          </Button>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex flex-col border border-gray-600 bg-gray-900 mb-12">
        <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2">
          <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center">
            <AttackPathwayIcon class="mr-1" />
            <span class="relative inline-block z-10 text-base sm:text-lg">
              Toggle Attack Pathway Visibility
            </span>
          </h2>
        </div>
        <ul v-if="Object.keys(parsedAttackPathwayFindings).length"
            class="h-40 overflow-scroll pb-2">
          <li v-for="(finding, index) in parsedAttackPathwayFindings"
              :key="`apfinding-edit-${index}`">
            <VisibilityToggle
              :id="String(finding.id)"
              :label="finding.label"
              :visible="finding.visible"
              :ipAddress="finding.ipAddress"
              :inFlight="checkVisibility(finding.id)"
              :action="updateAttackPathwayVisibility"
              :collection="parsedAttackPathwayFindings"
              @onClick="toggleVisibility"
            />
          </li>
        </ul>
      </div>
      <div class="flex flex-col border border-gray-600 bg-gray-900 mb-12">
        <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2">
          <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center">
            <WarningIcon class="mr-1" />
            <span class="relative inline-block z-10 text-base sm:text-lg">
              Toggle Finding Visibility
            </span>
          </h2>
        </div>
        <ul v-if="Object.keys(parsedFindings).length"
            class="h-40 overflow-scroll pb-2">
          <li v-for="(finding, index) in parsedFindings"
              :key="`finding-edit-${index}`">
            <VisibilityToggle
              :id="String(finding.id)"
              :label="finding.label"
              :visible="finding.visible"
              :ipAddress="finding.ipAddress"
              :inFlight="checkVisibility(finding.id)"
              :action="updateFindingVisibility"
              :collection="parsedFindings"
              @onClick="toggleVisibility"
              />
          </li>
        </ul>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div class="flex flex-col border border-gray-600 bg-gray-900">
          <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2">
            <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center">
              <TrashcanIcon class="mr-2" />
              <span class="relative inline-block z-10 text-base sm:text-lg">
              Delete Report
            </span>
            </h2>
          </div>
          <div class="flex flex-col p-4 min-h-40 justify-end">
            <template v-if="!confirmDelete">
              <p class="text-gray-200 text-center">
                Deleting this report will delete
                <span class="text-gray-100">Executive Report {{ parentReportID }}</span>
                <br>
                and all its associated child reports.
              </p>
              <div class="flex items-center justify-center my-4">
                <Button
                  @onClick='confirmDelete = true'
                  type="primary"
                  class="py-2 text-gray-300 mx-6">
                  Delete Report
                </Button>
              </div>
            </template>
            <template v-if="confirmDelete">
              <p class="text-gray-200 text-center">
                Are you sure you want to delete this report?
              </p>
              <div class="flex items-center justify-center my-4">
                <Button type="secondary"
                        class="py-2 text-gray-300 mx-6"
                        @onClick="confirmDelete = false">
                  Cancel
                </Button>
                <Button
                  @onClick='handleDelete'
                  type="primary"
                  class="py-2 text-gray-300 mx-6">
                  Delete
                </Button>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col border border-gray-600 bg-gray-900">
          <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2">
            <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center">
              <RecollateIcon class="mr-2" />
              <span class="relative inline-block z-10 text-base sm:text-lg">
              Recollate Report
            </span>
            </h2>
          </div>
          <div class="flex flex-col h-full justify-end p-4">
            <template v-if="!confirmRecollate">
              <p class="text-gray-200 text-center">
                This process is asynchronous and updates will not be immediate.
              </p>
              <div class="flex items-center justify-center my-4">
                <Button
                  @onClick='confirmRecollate = true'
                  type="primary"
                  class="py-2 text-gray-300 mx-6">
                  Recollate Report
                </Button>
              </div>
            </template>
            <template v-if="confirmRecollate">
              <p class="text-gray-200 text-center">
                Are you sure you want to recollate this report?
              </p>
              <div class="flex items-center justify-center my-4">
                <Button type="secondary"
                        class="py-2 text-gray-300 mx-6"
                        @onClick="confirmRecollate = false">
                  Cancel
                </Button>
                <Button
                  @onClick='handleRecollate'
                  type="primary"
                  class="py-2 text-gray-300 mx-6">
                  Recollate
                </Button>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col border border-gray-600 bg-gray-900">
          <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2">
            <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center">
              <span class="relative inline-block z-10 text-base sm:text-lg">
              Administrative Views
            </span>
            </h2>
          </div>
          <div class="block m-4">
            <div class="text-gray-100">
              <RadioButton
                id="superuserView"
                value="superuser"
                :checked="selectedView"
                label="Superuser View"
                @change="updateSelectedView"
                class="uppercase text-xs inline-block mr-4"
              />
              <RadioButton
                id="customerView"
                value="customer"
                :checked="selectedView"
                label="Customer View"
                @change="updateSelectedView"
                class="uppercase text-xs inline-block"
              />
            </div>
            <p class="text-sm mt-3 text-gray-100">
              <span v-if="selectedView === 'superuser'">Shows all data in a report.</span>
              <span v-else>Shows reports exactly how customers view them.</span>
            </p>
            <div class="text-gray-100 mt-4" v-if="selectedView === 'superuser'">
              <Checkbox id="checkbox"
                        :checked="adminViewActive"
                        label="Show raw data"
                        @change="setAdminViewActive"
                        class="uppercase text-xs"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AttackPathwayIcon from '@/assets/attackpathway.svg?inline';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Modal from '@/components/Modal.vue';
import ModalMixin from '@/mixins/ModalMixin';
import RadioButton from '@/components/RadioButton.vue';
import RecollateIcon from '@/assets/recollate-icon.svg?inline';
import TrashcanIcon from '@/assets/trash-can.svg?inline';
import WarningIcon from '@/assets/warning.svg?inline';
import VisibilityToggle from '@/components/VisibilityToggle.vue';

export default {
  name: 'AdminModal',
  components: {
    AttackPathwayIcon,
    Button,
    Checkbox,
    Modal,
    VisibilityToggle,
    WarningIcon,
    TrashcanIcon,
    RadioButton,
    RecollateIcon,
  },
  data() {
    return {
      confirmDelete: false,
      confirmRecollate: false,
      parsedFindings: [],
      parsedAttackPathwayFindings: [],
      loadingVisibilityToggles: [],
      hasEditedReport: false,
      isAdmin: false,
      originalSelectedView: null,
    };
  },
  computed: {
    ...mapGetters([
      'adminViewActive',
      'selectedView',
    ]),
  },
  methods: {
    ...mapActions([
      'deleteReport',
      'setAdminViewActive',
      'setSelectedView',
      'recollateReport',
      'updateFindingVisibility',
      'updateAttackPathwayVisibility',
    ]),
    handleDelete() {
      this.deleteReport(this.reportId)
        .then(() => this.close('new-report', false));
    },
    handleRecollate() {
      this.recollateReport(this.reportId)
        .then(() => this.close(null, this.hasEditedReport));
    },
    toggleVisibility(id, visible, action, collection) {
      this.hasEditedReport = true;
      this.loadingVisibilityToggles.push(id);
      action({
        id,
        visible,
      })
        .then((response) => {
          collection.forEach((finding, index) => {
            if (finding.id === response.id) {
              collection[index].visible = response.visible;
            }
          });
        })
        .finally(() => {
          const index = this.loadingVisibilityToggles.indexOf(id);
          if (index > -1) {
            this.loadingVisibilityToggles.splice(index, 1);
          }
        });
    },
    populateParsedFindings() {
      this.parsedFindings = this.findings.map((finding) => ({
        id: finding.id,
        visible: finding.visible,
        label: finding.rule.outcome,
        ipAddress: finding.ipAddress || '',
      }));
    },
    populateParsedAttackPathwayFindings() {
      this.parsedAttackPathwayFindings = this.attackPathwayFindings.map((finding) => ({
        id: finding.id,
        visible: finding.visible || false,
        label: finding.attackPathwayRule.outcome,
      }));
    },
    checkVisibility(id) {
      return this.loadingVisibilityToggles.includes(String(id));
    },
    updateSelectedView(view) {
      this.hasEditedReport = view !== this.originalSelectedView;
      this.setSelectedView(view);
    },
  },
  mixins: [ModalMixin],
  mounted() {
    this.populateParsedFindings();
    this.populateParsedAttackPathwayFindings();
    this.originalSelectedView = this.selectedView;
  },
  props: {
    reportId: {
      required: true,
    },
    /*
     * A list of Findings that can be hidden / shown on a report
     */
    findings: {
      type: Array,
      required: true,
    },
    /*
     * A list of Attack Pathway Findings that can be hidden / shown on a report
     */
    attackPathwayFindings: {
      type: Array,
      required: true,
    },
    /*
     * The report number of the executive report for Delete section warning text
     */
    parentReportID: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
  .triangle::before {
    @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
    transform skew-x-20;
    top: -1px;
    left: calc(100% - 0.5rem);
    width: calc(1rem + 5px);
    height: calc(100% + 2px);
    content: '';
  }
</style>
