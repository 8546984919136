<template>
  <div class="bg-gray-800 border border-gray-600 print:bg-white print:border-gray-200">
    <div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4">
      <div class="flex flex-col justify-between min-w-0">
        <div class="flex items-center sm:mr-8">
          <h2 class="relative text-2xl print:text-base mr-3">
            <div class="glow-block print:hidden"
                 :class="[
                     {'glow-block--yellow': findingsCount},
                     {'glow-block--green': !findingsCount}
                   ]"></div>
            <span class="ml-4">{{ ip }}</span>
          </h2>
          <div class="flex uppercase text-xs px-1 font-semibold tracking-wider border
                      leading-relaxed print:font-small"
               :class="[
                 {'bg-gray-600 text-gray-200 border-gray-600': category === 'external'},
                 // eslint-disable-next-line max-len
                 {'bg-yellow-700 text-gray-200 border-yellow-500 shadow-glowyellow': category === 'internal'},
               ]">
            <span class="block">{{ category }}</span>
          </div>
        </div>

        <div class="ml-4 mt-2 md:mt-0 md:mr-8 print:hidden">
          <span class="flag-icon"
                :title="country.toLowerCase()"
                :aria-label="country.toLowerCase()"
                :class="`flag-icon-${country.toLowerCase()}`"
                @mouseover="showTooltip = true" @mouseout="showTooltip = false">
            <span v-if="showTooltip" class="tooltip">
              {{ country }}
            </span>
          </span>
        </div>
        <div class="ml-4 mt-2 lg:mt-0 lg:mr-8">
          <span v-if="asn">
            {{ asn }}
          </span>
          <span v-else
                class="italic text-gray-300">
            No ASN
          </span>
        </div>
        <div class="ml-4 mt-2 lg:mt-0 lg:mr-8">
          <span v-if="org">
            {{ org }}
          </span>
          <span v-else
                class="italic text-gray-300">
            No Organization
          </span>
        </div>
      </div>
      <div class="flex ml-4 mt-4
                  sm:justify-end sm:ml-0 sm:mt-0 sm:pr-8 flex-shrink-0 print:hidden">
        <WarningIcon v-if="findingsCount" class="inline-block mr-1" />
        <span
            class="print:bg-white print:border-gray-200"
            :class="[
                {'italic text-gray-300': findingsCount === 0},
              ]">
            {{ findingsCount }} {{ 'Finding' | pluralize(findingsCount) }}
        </span>
      </div>
    </div>
    <div
      v-if="isSuperuser"
      class="block mb-2 pl-5 print:hidden">
      <Checkbox
        :id="`${ip}-dir-enum`"
        data-testid="host-checkbox"
        :checked="targetsForDictEnum"
        @change="() => $emit('dirEnumTargetUpdated',  ip)"
        label="Select for directory enumeration"
        :value="ip"/>
    </div>
    <template v-if="expanded || isPrint">
      <div
          v-if="categoryExplanation"
          class="border-t border-gray-600 pt-2 px-2">
        <p class="text-sm mb-2 bg-gray-900 text-gray-200 border border-gray-600 p-2">
          <pre class="break-all whitespace-pre-wrap">{{ categoryExplanation }}</pre>
        </p>
      </div>
      <div v-if="findingsCount" class="border-t border-gray-600 p-2">
        <div class="relative bg-gray-900 pb-2 pt-3 print:py-1
            print:bg-white print:border-gray-200">
          <div class="warning-strip print:hidden"></div>
          <div class="flex mb-2">
            <WarningIcon class="mx-2" />
            <span class="uppercase relative inline-block">
              Findings [{{ findingsCount }}]
            </span>
          </div>
          <ul v-if="Object.keys(findings).length">
            <li v-for="(finding, index) in findings"
                class="yellow-block flex relative pl-3 mb-2 pr-2"
                :key="`finding-${index}`">
              <span>{{ finding }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="border-t border-gray-600 p-2">
        <div class="flex flex-col mb-1">
            <span class="text-lg print:text-base">

              {{ 'Subdomain' | pluralize(subdomains.length) }} [{{ subdomains.length }}]
            </span>
        </div>
        <div
          v-if="subdomains.length > 0"
          :class="{'print:hidden': subdomains.length === 0}"
          class="flex flex-col lg:flex-row">
          <div
            :class="{ 'px-2' : screenshots.length > 0 }"
            class="lg:w-1/4 flex-grow print:w-full">
            <div
              class="uppercase text-gray-300 text-xs font-semibold tracking-widest mb-2">
              Subdomains
            </div>
            <ul class="print:flex print:flex-row print:flex-wrap">
              <li
                v-for="(subdomain, index) in subdomains"
                  :key="`${ip}-subdomain-${index}`"
              >
                <Checkbox
                  v-if="isSuperuser"
                  :id="`${ip}-${subdomain}_dict-enum`"
                  data-testid="subdomain-checkbox"
                  :checked="targetsForDictEnum"
                  @change="() => $emit('dirEnumTargetUpdated',  `${subdomain}.${rootDomain}`)"
                  :label="subdomain"
                  :value="`${subdomain}.${rootDomain}`"/>
                <div v-else>{{ subdomain }}</div>
              </li>
            </ul>
          </div>
          <div
            class='lg:w-3/4'
            :class="{'print:hidden': screenshots.length === 0}"
            v-if="screenshots.length > 0">
            <div class="uppercase text-gray-300 text-xs font-semibold tracking-widest mb-2">
              Screenshots
            </div>
            <ul
              class='grid grid-cols-2 gap-2 mb-2 lg:grid-cols-3 pr-2'>
              <li v-for="(screenshot, index) in screenshots"
                  class="flex flex-col uppercase px-1 pt-1 pb-2 bg-black font-semibold"
                  :key="`${ip}-subdomain-${index}`">
                <div
                  v-if="screenshot.file">
                  <a :href="screenshot.file"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img :src="screenshot.file" loading="lazy"/>
                  </a>
                </div>
                <div class="text-xs text-gray-200 px-1 pt-2 truncate">
                  <CopySubdomain
                    :subdomain="screenshot.domainName"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <p v-else class="italic text-gray-300 leading-5"
           :class="{'print:hidden': subdomains.length === 0}">
          No subdomains found on this host.
        </p>
      </div>
      <div class="flex flex-col lg:flex-row border-t border-b border-gray-600 px-2"
           :class="{'print:hidden': criticalPorts.length  === 0}">
        <div class="lg:w-3/4 lg:border-r border-gray-600 pt-2 pb-4">
          <div class="flex flex-col mb-2">
            <span class="text-lg">
              {{ 'Port' | pluralize(ports.length) }} [{{ ports.length }}]
            </span>
          </div>
          <div v-if="criticalPorts.length !== 0">
            <ul class="grid gap-2 grid-cols-3">
              <li v-for="(port, index) in criticalPorts"
                  class="flex flex-col uppercase px-1 pt-1 pb-2 bg-black font-semibold mb-2"
                  :key="`${ip}-port-${index}`">
                <div
                  class=""
                  v-if="Object.keys(port).includes('screenshot') && port.screenshot">
                  <a :href="port.screenshot"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img :src="port.screenshot" loading="lazy"/>
                  </a>
                </div>
                <div class="flex justify-between px-1 pt-1">
                  <span class="text-white">{{ port.portid }}</span>
                  <span class="flex items-center text-2xs text-gray-200">
                    <template v-if="port.state === 'open'">
                      <div class="rounded-full text-2xs bg-green-500 w-2 h-2 mr-1 shadow-glowgreen">
                      </div>
                    </template>
                    <template v-if="port.state === 'closed'">
                      <div
                        class="rounded-full text-2xs border border-gray-200 bg-black w-2 h-2 mr-1">
                      </div>
                    </template>
                    <div class="leading-3">{{ port.state }}</div>
                  </span>
                </div>
                <div class="text-xs text-gray-200 px-1">
                  {{ port.protocol }}
                  <template v-if="port.banner">
                    <span class="text-gray-600 mx-1">|</span>
                    <span class="truncate block">
                      {{ port.banner }}
                    </span>
                  </template>
                  <template v-else-if="port.product">
                    <span class="text-gray-600 mx-1">|</span>
                    {{ port.product }}
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <span v-if="criticalPorts.length == 0"
                :class="{'print:hidden': criticalPorts.length === 0}"
                class="italic text-gray-300">
            No critical port information available.
          </span>
          <div class="" v-if="otherPorts.length > 0">
            <span class="uppercase text-gray-300 text-xs font-semibold tracking-widest"
                  v-tooltip="
                'There are additional open ports that have no additional information.'
              "
            >
              [{{ otherPorts.length }}] additional {{ 'port' | pluralize(otherPorts.length) }} open
            </span>
          </div>
        </div>
        <div class="lg:w-1/4 border-b lg:border-b-0 border-gray-600 p-2 print:border-b-0">
          <div class="flex flex-col mb-2">
            <span class="text-lg" :class="{'print:hidden': technologies.length === 0}">
              {{ 'Technology' | pluralize(technologies.length) }} [{{ technologies.length }}]
            </span>
          </div>
          <ul v-if="technologies.length > 0">
            <li v-for="(technology, index) in technologies"
                class="flex"
                :key="`${ip}-tech-${index}`">
              <template v-if="technology.classification === 'favorable'">
                <FavorableIcon v-tooltip="getVersionTooltip(technology.isUpToDate)"
                  class="mr-1" />
              </template>
              <template v-if="technology.classification === 'unfavorable'">
                <WarningIcon v-tooltip="getVersionTooltip(technology.isUpToDate)"
                  class="mr-1" />
              </template>
              {{ technology.description }}
              <span
                v-if="technology.semanticVersion"
                v-tooltip="getVersionTooltip(technology.isUpToDate)"
                :class="getVersionClass(technology.isUpToDate)"
                class="text-gray-300 text-xs font-semibold
                cursor-default inline-block ml-1 leading-6">
                  v{{ technology.semanticVersion }}
              </span>
            </li>
          </ul>
          <p class="italic text-gray-300 leading-5"
             :class="{'print:hidden': technologies.length === 0}"
             v-else>
            No relevant technologies found on this host.
          </p>
        </div>
      </div>
      <div class="raw-data p-2 m-2" v-if="isSuperUser && adminViewActive">
      <Button type="text-small"
              class="py-2 text-gray-300 pl-2"
              @onClick="showRawData = !showRawData">
              {{ showOrHideByValue(showRawData) }}
              Raw Data [{{ renderCorrectExpandElement(showRawData) }}]
            </Button>
            <RawJSON
              v-if='showRawData'
              :json="rawData" />
      </div>
    </template>
    <div class="border-t border-gray-600 print:hidden">
      <Button type="text-small"
              class="w-full py-2 text-gray-300"
              @onClick="expanded = !expanded">
        {{ expanded ? 'Collapse' : 'Expand' }}
        <template v-slot:iconRight>
          <Chevron class="text-gray-200 transform
                            transition-transform duration-200 linear"
                   :class="{'-rotate-180': expanded}"/>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Chevron from '@/assets/chevron.svg?inline';
import CopySubdomain from '@/components/Host/CopySubdomain.vue';
import FavorableIcon from '@/assets/favorable.svg?inline';
import RawJSON from '@/components/Admin/RawJSON.vue';
import WarningIcon from '@/assets/warning.svg?inline';

export default {
  name: 'Host',
  components: {
    Button,
    Checkbox,
    Chevron,
    CopySubdomain,
    FavorableIcon,
    RawJSON,
    WarningIcon,
  },
  methods: {
    renderCorrectExpandElement(value) {
      return value ? '-' : '+';
    },
    showOrHideByValue(value) {
      return value ? 'Hide' : 'Show';
    },
    getVersionTooltip(version) {
      if (version === null) return '';
      if (version) return 'Up to date';
      return 'Out of date';
    },
    getVersionClass(version) {
      if (version === null) return 'text-gray-300';
      if (version) return 'text-green-500';
      return 'text-yellow-500';
    },
  },
  props: {
    /*
     The IP of the host
     */
    ip: {
      type: String,
      required: true,
    },
    /*
     Whether the Host is External or Internal
     */
    category: {
      type: String,
      required: true,
    },
    /*
    Explanation of the categorization if available
    */
    categoryExplanation: {
      type: String,
      required: false,
    },
    /*
     The country the host is in
     */
    country: {
      type: String,
      required: true,
    },
    /*
     The ASN of the host
     */
    asn: {
      type: [String, Number],
      required: true,
    },
    /*
     The Organization the host belongs to
     */
    org: {
      type: String,
      required: true,
    },
    /*
     The findings for the host
     */
    findings: {
      type: Array,
      required: true,
    },
    /*
     An array of subdomains found for the host
     */
    subdomains: {
      type: Array,
      required: true,
    },
    /*
     An array of technology objects
     */
    technologies: {
      type: Array,
      required: true,
    },
    /*
     An array of port objects
     */
    ports: {
      type: Array,
      required: true,
    },
    /*
     Overrides state of expanded so all hosts can be collapsed / expanded on report
     */
    forceExpand: {
      type: Boolean || null,
    },
    /*
     Determines if superuser features are available
     */
    isSuperuser: {
      type: Boolean || null,
    },
    /*
     An array of screenshots from subdomains
     */
    screenshots: {
      type: Array,
      required: true,
    },
    /*
     The root domain (used to create list of subdomains to target for directory bruteforcing)
     */
    rootDomain: {
      type: String,
    },
    targetsForDictEnum: {
      type: Array,
    },
    rawData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded: false,
      showRawData: false,
      filteredSubdomains: [],
      showTooltip: false,
    };
  },
  mounted() {
    if (this.forceExpand !== null) {
      this.expanded = this.forceExpand;
    }
  },
  computed: {
    ...mapGetters(['isSuperUser', 'adminViewActive']),
    findingsCount() {
      return this.findings.length || 0;
    },
    criticalPorts() {
      return this.ports.filter((p) => p.banner || p.product || p.screenshot);
    },
    otherPorts() {
      return this.ports.filter((p) => !(p.banner || p.product || p.screenshot));
    },
    isPrint() {
      return window.matchMedia('print').matches;
    },
  },
  watch: {
    forceExpand(newForceExpand) {
      if (newForceExpand !== null) {
        this.expanded = newForceExpand;
      }
    },
  },
};
</script>

<style scoped>
.glow-block {
  @apply absolute left-0;
  top: 0rem;
  width: 2px;
  height: 100%;
  content: '';

  &.glow-block--yellow {
     @apply bg-yellow-500 shadow-glowyellow;
   }

  &.glow-block--green {
     @apply bg-green-500 shadow-glowgreen;
   }
}

.warning-strip {
  @apply absolute h-px w-full top-0 left-0;
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 100% 88px;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(-50deg,
  transparent,
  transparent 11px,
  rgba(249, 194, 46, 1) 11px,
  rgba(249, 194, 46, 1) 22px);
}

.yellow-block::after {
  @apply absolute left-0 bg-yellow-500;
  top: 0.35rem;
  width: 2px;
  height: .75rem;
  content: '';
}

.tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-left: 5px;
  z-index: 10;
}
</style>
