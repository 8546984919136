<template>
  <component
    :is='status !== "failed" ? "router-link" : "div"'
    :to="status !== 'failed' ? {
      name: 'domain-report',
      params: {
        execReport: executiveReportID,
        domainReport: id,
      },
    } : {}"
    class="group outline-none"
    :class="{ 'link-disabled cursor-default m-1':  status === 'failed' }">
    <div class="pt-1 pb-1 overflow-hidden h-full"
    :class="{ 'pl-0':  status === 'failed', 'pl-2': status !== 'failed' }">
      <div ref="domain"
          class="flex flex-col relative bg-gray-800 w-full pt-3 pb-4
                 transition-colors duration-200 ease h-full"
          :class="{ 'group-hover:bg-gray-700 group-focus:bg-gray-700': status !== 'failed' }">
        <div class="justify-start">
          <h2 class="relative text-2xl mb-2">
            <div class="glow-block"
                 :class="[
                   {'glow-block--red': (status === 'failed')},
                   {'glow-block--yellow': (
                     status !== 'failed' && (findingsCount || attackPathwaysCount
                    ))},
                   {'glow-block--blue':
                    status !== 'failed' && (!findingsCount && !attackPathwaysCount)}
                 ]"></div>
            <span class="ml-4">{{ name }}</span>
          </h2>
          <div
            v-if="status !== 'failed'"
            class="grid grid-cols-2 ml-4 mb-2">
            <div class="flex items-center text-base">
              <template v-if="attackPathwaysCount">
                <WarningIcon />
                <div class="uppercase font-semibold">
                  [{{ attackPathwaysCount }}]
                  {{ 'Attack Pathway' | pluralize(attackPathwaysCount) }}
                </div>
              </template>
              <template v-else>
                <div class="text-gray-300 italic ">
                  No Attack Pathways
                </div>
              </template>
            </div>
            <div class="flex items-center text-base">
              <template v-if="findingsCount">
                <WarningIcon />
                <div class="uppercase font-semibold">
                  [{{ findingsCount }}] {{ 'Finding' | pluralize(findingsCount) }}
                </div>
              </template>
              <template v-else>
                <div class="text-gray-300 italic ">
                  No Findings
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="status !== 'failed'"
          class="grid grid-cols-2 sm:grid-cols-4 sm:grid-rows-1 gap-1 mx-4 mb-3 flex-grow">
          <img v-for="(screenshot, index) in onDisplayScreenshots"
               :src="screenshot.file"
               :key="`screenshot-${screenshot.ipAddress}-${index}`"
               alt=""
               class=""/>
        </div>
        <div
          v-if="status !== 'failed'"
          class="mx-4 flex justify-between">
          <div class="text-xs text-gray-100 font-bold tracking-wider uppercase
                      transition-colors duration-150 ease-in">
            View Report
            <ArrowRight class="inline-block opacity-0 text-gray-300 transform -translate-x-3
                             group-hover:opacity-100 group-focus:opacity-100
                             group-hover:translate-x-0 group-focus:translate-x-1
                             transition duration-150 ease-in-out" />
          </div>
          <div class="host">
            <p class="text-xs text-gray-100 font-bold tracking-wider uppercase
                      transition-colors duration-150 ease-in">
                      {{ hostCount }} known {{ 'host' | pluralize(hostCount) }}
              </p></div>
        </div>
        <div class="flex items-center justify-center h-full"
          v-if="status === 'failed'">
            <p class="mr-2 uppercase font-semibold tracking-wider text-red-600">
               Report Failed
            </p>
          </div>
      </div>
    </div>
  </component>
</template>

<script>
import WarningIcon from '@/assets/warning.svg?inline';
import ArrowRight from '@/assets/arrow_right.svg?inline';

const SCREENSHOT_DISPLAY_COUNT = 4;
export default {
  name: 'DomainCard',
  components: {
    WarningIcon,
    ArrowRight,
  },
  data() {
    return {
      SCREENSHOT_DISPLAY_COUNT,
    };
  },
  computed: {
    isPrint() {
      return window.matchMedia('print').matches;
    },
    onDisplayScreenshots() {
      return this.isPrint ? this.screenshots : this.screenshots.slice(0, SCREENSHOT_DISPLAY_COUNT);
    },
  },
  props: {
    /*
      The ID of the parent report
     */
    executiveReportID: {
      type: String,
      required: true,
    },
    /*
      The ID of the domain report
     */
    id: {
      type: Number,
      required: true,
    },
    /*
      The URL of the domain
     */
    name: {
      type: String,
      required: true,
      default: '',
    },
    /*
      The number of findings on the domain
     */
    findingsCount: {
      type: Number,
      default: 0,
    },
    /*
      The number of attack pathways found on the domain
     */
    attackPathwaysCount: {
      type: Number,
      default: 0,
    },
    /*
      The number of hosts found on the
     */
    hostCount: {
      type: Number,
      default: 0,
    },
    /*
     An array of screenshots of the domain
     */
    screenshots: {
      type: Array,
      default: () => [],
    },
    /*
     An array of screenshots of the domain
     */
    status: {
      type: String,
      default: 'in-flight',
    },
  },
};
</script>

<style scoped>
.link-disabled {
  @apply bg-gray-800;
}
.glow-block {
  @apply absolute left-0;
  top: 0rem;
  width: 2px;
  height: 100%;
  content: '';
  &.glow-block--yellow {
    @apply bg-yellow-500 shadow-glowyellow;
  }
  &.glow-block--blue {
    @apply bg-blue-100 shadow-glowblue100;
  }
  &.glow-block--red {
    @apply bg-red-600 shadow-glowred100;
  }
}
</style>
