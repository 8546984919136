<template>
  <button @click="onClick"
          class="cursor-pointer">
    <div
      class="select-toggle-indicator relative
            inline-block w-4 h-4 border border-gray-200 bg-gray-700 rounded"
      :class="[{'deselected-toggle': !selectedItemsCount},
              {'semiselected-toggle': selectedItemsCount < allItemsCount}]"
      data-testid="select-all-toggle-indicator"
    ></div>
    <label class="mb-0 cursor-pointer pl-1 text-gray-200 font-semibold inline-block">
      Select All
    </label>
  </button>
</template>

<script>
export default {
  name: 'SelectAllToggle',
  props: {
    /**
     * number of all available items in the list
     */
    allItemsCount: {
      type: Number,
    },
    /**
     * number of selected items in the list
     */
    selectedItemsCount: {
      type: Number,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
  .select-toggle-indicator {
    top: 0.15rem;
  }

  .select-toggle-indicator::after {
    @apply absolute w-2 h-2 left-0 rounded-sm bg-white;
    content: "";
    left: 3px;
    top: 3px;
    transition: background-color 0.2s ease, opacity 0.2s ease;
  }

  .deselected-toggle::after {
    @apply opacity-0;
  }

  .semiselected-toggle::after {
    @apply h-1 rounded-none;
    top: 5px
  }

  button:focus {
    @apply outline-none;
  }

  button:focus .select-toggle-indicator {
    @apply border-blue-100 shadow-glowblue100;
  }

  button:focus .select-toggle-indicator::after {
    @apply bg-blue-100;
  }
</style>
