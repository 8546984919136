<template>
  <Modal @close="close(null)">
    <template v-slot:header>
      <div
        class="relative left-0 right-0 flex justify-center items-center mb-6 -mt-6"
      >
        <div
          class="border-gray-600 border bg-gray-900 py-1 px-12 transform skew-x-20"
        >
          <h2
            class="text-white uppercase text-2xl
            font-medium transform -skew-x-20"
          >
            New Subset Report
          </h2>
        </div>
        <div class="absolute right-0 mt-8 mr-3">
          <Button type="text-small" @onClick="close(null)"> Close </Button>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <p class="text-center">[INSTRUCTIONS]</p>
      <p class="text-center mb-3">
        This will create a new executive report consisting of the domain reports
        selected below.
      </p>
      <div>
        <TextInput
          label="REPORT NAME"
          v-model="name"
          :errors="errors.filter(e => e.field === 'name').map(e => e.message)"
          placeholder="Enter a name..."
        />
      </div>
      <template v-if="domains.length">
        <div class="w-full flex mt-4 mb-2">
          <div
            class="text-gray-200 w-3/4 uppercase font-semibold text-xs tracking-widest pl-4"
          >
            Domain
          </div>
          <div
            class="text-gray-200 w-1/4 uppercase font-semibold text-xs tracking-widest"
          >
            Report id
          </div>
        </div>
        <div class="border border-gray-600 py-4 flex flex-col">
          <div
            v-for="(domain, index) in domains"
            class="text-left flex"
            :key="index"
          >
            <div class="w-3/4 pl-4">
              <Checkbox
                :id="`domain-${domain.id.toString()}`"
                :value="domain.id.toString()"
                :label="domain.name"
                v-model="reportIds"
              />
            </div>
            <div class="w-1/4">
              {{ domain.id }}
            </div>
          </div>
        </div>
        <div class="mt-2 pl-4 italic text-gray-200">
          {{ reportIds.length }} selected domains
        </div>
        <div class="flex justify-center">
          <Button
            :inFlight='customReportIsLoading'
            type="primary"
            @onClick="() => createCustomReport({reportIds, name})"
            class="mt-8"
            >Create Report</Button
          >
        </div>
        <div class="flex flex-col items-center justify-center">
          <ErrorMessage
            class="block my-2"
            v-for="(error, index) in errors"
            :key="`error${index}`"
            :error="error.message" />
        </div>
      </template>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Modal from '@/components/Modal.vue';
import ModalMixin from '@/mixins/ModalMixin';
import TextInput from '@/components/TextInput.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'SubsetModal',
  components: {
    Button,
    ErrorMessage,
    TextInput,
    Checkbox,
    Modal,
  },
  mixins: [ModalMixin],
  computed: {
    ...mapGetters(['globalErrors', 'customReportIsLoading', 'newCustomReportEvent']),
    errors() {
      const normalizedErrors = [];
      if (this.globalErrors.some((e) => e.fieldName === 'name')) {
        normalizedErrors.push({ field: 'name', message: 'You must provide a name for your custom report' });
      }
      if (this.globalErrors.some((e) => e.fieldName === 'reportIds')) {
        normalizedErrors.push({ field: 'reportIds', message: 'You must select domains / reports to generate a new custom report' });
      }

      return normalizedErrors;
    },
  },
  data() {
    return {
      reportIds: [],
      name: null,
    };
  },
  methods: {
    ...mapActions(['createCustomReport']),
  },
  props: {
    domains: {
      type: Array,
      required: true,
    },
  },
  watch: {
    newCustomReportEvent(newCustomReportEvent) {
      if (newCustomReportEvent && newCustomReportEvent.report) {
        this.close();
        this.$router.push({ name: 'executive-report', params: { execReport: newCustomReportEvent.report } });
      }
    },
  },
};
</script>
