<template>
  <div class="break-inside flex flex-col border border-gray-600 bg-gray-900
              print:bg-white print:border-gray-200">
    <div class="-mt-4 ml-3 mb-2">
      <h2 class="triangle relative inline-flex uppercase pl-2 py-1 bg-gray-900
                 border-t border-l border-b border-gray-600
                 print:bg-white print:border-gray-200">
        <Warning class="mr-1 print:hidden h-6 w-6" />
        <span class="relative inline-block z-10
                     print:tracking-widest print:text-black print:font-semibold">
          Findings [{{ parsedFindingsCount }}]
        </span>
      </h2>
    </div>
    <ul v-if="Object.keys(parsedFindings).length">
      <li v-for="(finding, _, index) in parsedFindings"
          class="yellow-block flex relative pl-3 mb-2 pr-2 print:hidden"
          :key="`finding-${index}`">
        <span
          class="mr-4 text-gray-200"
          v-if="finding.source !== CONST.findings.GENERIC">{{ finding.count }}</span>
        <span>{{ finding.outcome }}</span>
      </li>
      <!-- Print Version -->
      <li v-for="(finding, _, index) in parsedFindings"
          class="hidden mx-3 py-2 print:flex"
          :class="{'border-t border-gray-100': index > 0}"
          :key="`print-finding-${index}`">
        <span class="mr-4"
              v-if="finding.source !== CONST.findings.GENERIC">
              {{ finding.count }}
        </span>
        <span>{{ finding.outcome }}</span>
      </li>
      <!-- End Print Version -->
    </ul>
    <ul v-if="showResolved && Object.keys(parsedResolvedFindings).length">
      <li v-for="(finding, _, index) in parsedResolvedFindings"
          class="red-block flex relative pl-3 mb-2 pr-2 print:hidden"
          :key="`resolved-finding-${index}`">
        <span class="mr-4 text-gray-300">{{ finding.count }}</span>
        <span class="text-gray-300 line-through">{{ finding.outcome }}</span>
      </li>
      <!-- Print Version -->
      <li v-for="(finding, _, index) in parsedResolvedFindings"
          class="hidden mx-3 py-2 border-t border-gray-100 print:flex"
          :class="{'border-t-0': parsedFindings.length && index === 0}"
          :key="`print-resolved-finding-${index}`">
        <span class="mr-4">{{ finding.count }}</span>
        <span>{{ finding.outcome }}</span>
      </li>
      <!-- End Print Version -->
    </ul>
    <div v-if="(!showResolved
                && Object.keys(parsedFindings).length === 0)
                || (showResolved
                && Object.keys(parsedFindings).length === 0
                && Object.keys(parsedResolvedFindings).length === 0)"
         class="px-4 pt-4 pb-8 text-center">
      <h2 class="uppercase font-medium tracking-widest text-lg text-gray-200">
        No Findings Discovered
      </h2>
    </div>
  </div>
</template>

<script>
import Warning from '@/assets/warning.svg?inline';
import constants from '@/config/constants';

export default {
  name: 'Findings',
  components: {
    Warning,
  },
  data() {
    return {
      CONST: { ...constants },
    };
  },
  props: {
    /*
      An array of Finding objects
     */
    findings: {
      type: Array,
      default: () => [],
    },
    /*
      An array of resolved Finding objects
     */
    resolvedFindings: {
      type: Array,
      default: () => [],
    },
    /*
      Determines whether resolved findings are shown or not
     */
    showResolved: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedFindings() {
      let parsedFindings = {};

      if (this.findings) {
        parsedFindings = this.parseFindings(this.findings);
      }

      return parsedFindings;
    },
    parsedResolvedFindings() {
      let parsedResolvedFindings = {};

      if (this.resolvedFindings) {
        parsedResolvedFindings = this.parseFindings(this.resolvedFindings);
      }

      return parsedResolvedFindings;
    },
    parsedFindingsCount() {
      let count = 0;
      Object.keys(this.parsedFindings).forEach((id) => {
        count += this.parsedFindings[id].count;
      });
      return count;
    },
  },
  methods: {
    /*
     * Parses out the Count and Outcome of findings to easily display in template
     */
    parseFindings(findings) {
      const parsedFindings = {};

      findings.forEach((finding) => {
        if (parsedFindings[finding.rule.id] === undefined) {
          parsedFindings[finding.rule.id] = {};
        }

        if (parsedFindings[finding.rule.id].count === undefined) {
          parsedFindings[finding.rule.id].count = 1;
        } else {
          const { count } = parsedFindings[finding.rule.id];
          parsedFindings[finding.rule.id].count = count + 1;
        }
        if (parsedFindings[finding.rule.id].outcome === undefined) {
          parsedFindings[finding.rule.id].outcome = finding.rule.outcome;
        }
        if (parsedFindings[finding.rule.id].source === undefined) {
          parsedFindings[finding.rule.id].source = finding.source;
        }
      });

      return parsedFindings;
    },
  },
};
</script>

<style scoped>
.triangle::before {
  @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.5rem);
  width: calc(1rem + 5px);
  height: calc(100% + 2px);
  content: '';
}

@screen print {
  .triangle::before {
    @apply bg-white -skew-x-20 border-gray-200;
  }
}

.yellow-block::after {
  @apply absolute left-0 bg-yellow-500;
  top: 0.35rem;
  width: 2px;
  height: .75rem;
  content: '';
}

.red-block::after {
  @apply absolute left-0 bg-orange-400;
  top: 0.35rem;
  width: 2px;
  height: .75rem;
  content: '';
}
.break-inside {
  break-inside: avoid;
}
</style>
