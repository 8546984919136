<template>
  <div class="bg-black fixed bottom-0 w-full py-3 px-8 border-t border-gray-600 z-20">
    <div v-if="showSuccess">
      <div>
        <span class="font-bold text-green-500">Success! </span>
        <span>Directory enumation has begun on submitted targets.
        This may take several minutes to complete.</span>
      </div>
    </div>
    <div v-else-if="showError">
      <ErrorMessage error="Request failed. Please reload the page and try again." />
    </div>
    <div v-else class="w-full flex justify-between flex-col md:flex-row">
      <div class="mb-4 md:mb-0">
        <p>
          {{ targets.length }} {{'target' | pluralize(targets.length) }}
          selected for directory enumeration
        </p>
        <div v-if="expanded" class="py-1">
          <div v-for="target in targets"
               :key="target"
               class="text-gray-200">{{ target }}</div>
        </div>
        <Button type="text-small"
                class="py-0 text-gray-300"
                @onClick="expanded = !expanded">
          {{ expanded ? 'Hide' : 'Show' }} {{'target' | pluralize(targets.length) }}
          <template v-slot:iconRight>
            <Chevron class="text-gray-200 transform
                            transition-transform duration-200 linear"
                     :class="{'-rotate-180': expanded}"/>
          </template>
        </Button>
      </div>
      <div>
        <Button type="secondary"
                @onClick="cancelAction"
                :disabled="isLoading"
                class="mr-4">Cancel</Button>
        <Button type="primary"
                :inFlight="isLoading"
                @onClick="submitAction">Start Directory enumeration</Button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/Button.vue';
import Chevron from '@/assets/chevron.svg?inline';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'ReportActionBar',
  components: {
    Button,
    Chevron,
    ErrorMessage,
  },
  props: {
    /**
     * lists of targets to display
     */
    targets: {
      type: Array,
    },
    /**
     * Label for button
     */
    primaryActionLabel: {
      type: String,
    },
    /**
     * Status of submitAction request
     */
    requestStatus: {
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    isLoading() {
      return this.requestStatus === 'loading';
    },
    showSuccess() {
      return this.requestStatus === 'success';
    },
    showError() {
      return this.requestStatus === 'error';
    },
  },
  methods: {
    cancelAction() {
      this.$emit('cancelAction');
    },
    submitAction() {
      this.$emit('submitAction');
    },
  },
};
</script>
