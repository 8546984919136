var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex mb-3"},[_c('h2',{staticClass:"relative inline-flex uppercase pl-2 py-1 bg-gray-900 border-t border-l border-b mr-4",class:[
          {'triangle--yellow border-yellow-500': _vm.showCriticalStyles},
          {'triangle border-gray-600': _vm.showDefaultStyles},
          ]},[_c('span',{staticClass:"relative inline-block z-10 print:tracking-widest print:text-black print:font-semibold"},[_vm._v(" "+_vm._s(_vm.threatName[_vm.threatLevel])+" Threat ")])]),_c('div',{staticClass:"ml-2 w-3 self-stretch border border-yellow-500 transform skew-x-20 bg-yellow-500"}),_c('div',{staticClass:"ml-2 w-3 self-stretch border transform skew-x-20",class:[
          {'bg-yellow-500 border-yellow-500': _vm.threatLevel === 2 || _vm.threatLevel === 1},
          {'bg-gray-900 border-gray-600': _vm.threatLevel === 0},
          ]}),_c('div',{staticClass:"ml-2 w-3 self-stretch border transform skew-x-20",class:[
          {'bg-yellow-500 border-yellow-500': _vm.showCriticalStyles},
          {'bg-gray-900 border-gray-600': _vm.showDefaultStyles},
          ]})]),_c('p',{staticClass:"text-sm mb-2 text-gray-200 border border-gray-600 p-2"},[(_vm.threatLevel === 2)?[_vm._v(" These emails and associated plain-text passwords have been leaked. ")]:_vm._e(),(_vm.threatLevel === 1)?[_vm._v(" These emails and associated hashed passwords have been leaked."),_c('br'),_vm._v(" The plain-text password could potentially be recovered from the hashed password. ")]:_vm._e(),(_vm.threatLevel === 0)?[_vm._v(" These emails were included in data breaches, but no associated passwords were leaked. ")]:_vm._e()],2),(!_vm.isTrial)?_c('div',{staticClass:"p-2 overflow-y-auto print:overflow-y-visible overflow-x-visible",class:[
            {'cred-scroll-area--tall': _vm.showCriticalStyles},
            {'cred-scroll-area': _vm.showDefaultStyles},
            ]},[_c('div',{staticClass:"relative border",class:[
            {'bg-yellow-900 border-gray-900 print:bg-white': _vm.showCriticalStyles},
            {'bg-gray-800 border-gray-600': _vm.showDefaultStyles},
            ]},[_c('div',{staticClass:"outcome-border-tb print:hidden",class:[
                  {'outcome-border-tb--yellow': _vm.showCriticalStyles},
                ]}),_c('div',{staticClass:"outcome-border-lr print:hidden",class:[
                  {'outcome-border-lr--yellow': _vm.showCriticalStyles},
                ]}),_c('div',{staticClass:"outcome-corners outcome-corners--yellow",class:{'hidden': _vm.showDefaultStyles}}),_vm._l((_vm.expanded ? _vm.credentials : _vm.credentials.slice(0, _vm.defaultRowCount)),function(cred,index){return _c('div',{key:`credential-${index}`,class:[
             {'border-yellow-500': _vm.showCriticalStyles},
             {'border-gray-600': _vm.showDefaultStyles},
             {'border-t': index !== 0},
           ]},[_c('div',{staticClass:"flex items-center pt-2 pb-1 px-2 print bg:white"},[_c('UserIcon',{staticClass:"h-3 mr-2",class:[
                       {'text-yellow-500': _vm.showCriticalStyles},
                       {'text-gray-400': _vm.showDefaultStyles},
                     ]}),_c('span',{staticClass:"truncate print:text-gray-400"},[_vm._v(_vm._s(cred.username))])],1),_c('div',{staticClass:"w-full h-px my-1",style:({
                backgroundImage: `url(${_vm.showCriticalStyles ? _vm.LineDashedYellow : _vm.LineDashed})`
              })}),_c('div',{staticClass:"flex items-center pb-3 pt-1 px-2"},[_c('PasswordIcon',{staticClass:"h-3 mr-2",class:[
                       {'text-yellow-500': _vm.showCriticalStyles},
                       {'text-gray-400': _vm.showDefaultStyles},
                     ]}),(_vm.threatLevel === 2 && cred.password)?_c('span',{staticClass:"truncate print:text-gray-400"},[_vm._v(" "+_vm._s(cred.password)+" ")]):(_vm.threatLevel === 1)?_c('span',{staticClass:"uppercase text-gray-400"},[_vm._v(" HASHED PASSWORD AVAILABLE ")]):(_vm.threatLevel === 0)?_c('span',{staticClass:"uppercase text-gray-400"},[_vm._v(" PASSWORD UNAVAILABLE ")]):_vm._e()],1)])})],2)]):_c('div',{staticClass:"relative border mt-3",class:[
            {'bg-yellow-900 border-gray-900': _vm.threatLevel === 2},
            {'bg-gray-800 border-gray-600': _vm.threatLevel !== 2},
            ]},[_c('div',{staticClass:"outcome-border-tb print:hidden",class:[
                  {'outcome-border-tb--yellow': _vm.threatLevel === 2},
                ]}),_c('div',{staticClass:"outcome-border-lr print:hidden",class:[
                  {'outcome-border-lr--yellow': _vm.threatLevel === 2},
                ]}),_c('div',{staticClass:"outcome-corners outcome-corners--yellow",class:{'hidden': _vm.threatLevel !== 2}}),_c('p',{staticClass:"p-2"},[_vm._v(_vm._s(_vm.trialCount)+" "+_vm._s(_vm._f("pluralize")('credential',_vm.trialCount))+" discovered")])]),(_vm.defaultRowCount < _vm.credentials.length)?_c('div',{staticClass:"border-b border-r border-l bg-gray-800 border-gray-600 print:hidden"},[_c('Button',{staticClass:"w-full py-2 text-gray-300",attrs:{"type":"text-small"},on:{"onClick":function($event){_vm.expanded = !_vm.expanded}},scopedSlots:_vm._u([{key:"iconRight",fn:function(){return [_c('Chevron',{staticClass:"text-gray-200 transform transition-transform duration-200 linear",class:{'-rotate-180': _vm.expanded}})]},proxy:true}],null,false,2113784881)},[_vm._v(" "+_vm._s(_vm.expanded ? 'Collapse' : 'Expand')+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }