<template>
  <div class="text-black bg-white">
    <div v-if="executiveReport && executiveReport.id">
      <div class="grid grid-cols-5 px-8">
        <div class="col-span-4 text-left">
          <h1 class="text-2xl font-bold">Report #{{ executiveReport.id }}</h1>
          <p><strong>Created:</strong> {{ formattedCreatedDate }}</p>
          <p v-if="executiveReport.finishedAt">
            <strong>Runtime:</strong> {{ formattedRuntime }}
          </p>
          <p class="bg-gray-200 inline-block px-2">{{ executiveReport.reportInput }}</p>
        </div>
        <div
          v-if="executiveReport.artifacts
                && executiveReport.artifacts.length"
          class="col-span-1 text-left">
          <p class="font-bold">Artifacts</p>
          <div
            class="grid grid-cols-2">
            <div
              class="my-2"
              v-for="artifact in executiveReport.artifacts"
              v-bind:key="artifact.file">
              <a
                class="underline"
                :href="artifact.file"
                target="_blank">
                {{ artifact.source }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="text-left">
        <div
          v-if="executiveReport.status === 'in_flight'"
          class="col-span-4 text-center mx-8 mt-8 px-8 py-12 border border-red-600">
          <p class="font-bold text-red-600 text-xl">Report In-Flight</p>
        </div>
        <!-- Aggregated Results - IPs -->
        <div
          v-if="executiveReport.aggregatedResult
            && executiveReport.aggregatedResult.ips
            && executiveReport.aggregatedResult.ips.length"
          class="px-8 py-8">
          <div
            class="text-left p-3 my-3 border border-gray-400"
            v-for="ip in executiveReport.aggregatedResult.ips"
            v-bind:key="ip.ip">
            <div class="pb-4">
              <div>
                <span class="font-black text-2xl">{{ ip.ip }}</span>
                <span v-if="ip.category"> - {{ ip.category }}</span>
              </div>

              <div class="grid grid-cols-7">
                <div> <!-- ASN -->
                  <label class="text-xs font-bold text-gray-600">ASN</label>
                  <div>{{ ip.asn || 'null' }}</div>
                </div>
                <div> <!-- ISP -->
                  <label class="text-xs font-bold text-gray-600">ISP</label>
                  <div>{{ ip.isp || 'null' }}</div>
                </div>
                <div class="col-span-3"> <!-- Org -->
                  <label class="text-xs font-bold text-gray-600">Org</label>
                  <div>{{ ip.org || 'null' }}</div>
                </div>
                <div class="col-span-2">
                  <label class="text-xs font-bold text-gray-600">Location</label>
                  <div>
                    <span v-if="ip.city">{{ ip.city }}</span>
                    <span v-if="ip.region">, {{ ip.region }}, {{ ip.countryName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="grid grid-cols-9 gap-2 mt-3">
              <!-- Subdomains -->
              <div class="col-span-2">
                <p class="font-bold text-gray-600 text-sm">Subdomains</p>
                <div v-if="ip.subdomains && ip.subdomains.length">
                  <p
                    v-for="subdomain in ip.subdomains"
                    v-bind:key="subdomain">
                    {{ subdomain }}
                  </p>
                </div>
                <div v-else class="italic text-gray-600 text-sm">
                  No subdomains found
                </div>
              </div>
              <!-- Headers -->
              <div class="col-span-3 pl-3">
                <div v-if="ip.headers">
                  <p class="font-bold text-gray-600 text-sm">Headers</p>
                  <vue-json-pretty
                    :deep="4"
                    :showLength="true"
                    :data="ip.headers">
                  </vue-json-pretty>
                </div>
              </div>
              <!-- Technologies -->
              <div class="col-span-2">
                <p class="font-bold text-gray-600 text-sm">Technologies</p>
                <div v-if="ip.technologies && ip.technologies.length">
                  <p
                    v-for="tech in ip.technologies"
                    v-bind:key="tech">
                    {{ tech }}
                  </p>
                </div>
                <div v-else class="italic text-gray-600 text-sm">
                  No technologies found
                </div>
              </div>
              <!-- Ports -->
              <div class="col-span-2">
                <p class="font-bold text-gray-600 text-sm">Ports</p>
                <div v-if="ip.ports && ip.ports.length">
                  <div class="grid grid-cols-2 gap-2">
                    <div
                      class="col-span-1 py-1"
                      v-for="port in ip.ports"
                      v-bind:key="port.portid">
                      <span v-if="port.portid" class="font-bold">{{ port.portid }} - </span>
                      <span v-if="port.protocol">{{ port.protocol }}</span>
                      <span v-if="port.state">, {{ port.state }}</span>
                      <span v-if="port.product">, {{ port.product }}</span>
                      <div v-if="executiveReport.screenshots">
                        <div
                          class="my-2"
                          v-for="screenshot in
                          filterBy(executiveReport.screenshots, ip.ip, port.portid)"
                          v-bind:key="screenshot.file">
                          <a
                            :href="screenshot.file"
                            target="_blank">
                            <img :src="screenshot.file" class="border border-gray-400">
                          </a>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>
                <div v-else class="italic text-gray-600 text-sm">
                  No ports found
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="col-span-4 text-left px-8 py-12">
          <hr>
          <p class="italic mt-4">No aggregated results found</p>
        </div>

        <!-- Screenshots -->
        <div v-if="domainNameScreenshots.length > 0" class="
          col-span-4 text-center mx-8 mt-8 px-8 py-12 border border-gray-400 grid grid-cols-8
        ">
          <h2 class="col-span-8 text-left">Screenshots from Domains</h2>
            <div
              class="m-4 col-span-2"
              v-for="screenshot in domainNameScreenshots"
              v-bind:key="screenshot.file">
              <a :href="screenshot.domainName">{{screenshot.domainName}}</a>
              <a
                :href="screenshot.file"
                target="_blank">
                <img :src="screenshot.file" class="border border-gray-400">
              </a>
            </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <h1 class="text-2xl font-bold">No report selected</h1>
      <p class="italic">Make sure to add the idea of the report in the URL</p>
      <p class="bg-gray-200 inline-block px-2">/report/:id</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'ExecutiveReport',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      report: {},
      pollingCount: 0,
    };
  },
  watch: {
    executiveReport() {
      if (this.executiveReport && this.executiveReport.status === 'in_flight') {
        this.pollingCount += 1;
        setTimeout(() => this.fetchReport(this.$route.params.execReport), this.pollingInterval);
      }
    },
    $route() {
      if (this.$route.params.execReport) {
        this.fetchReport(this.$route.params.execReport);
      }
    },
  },
  mounted() {
    if (this.$route.params.execReport) {
      this.fetchReport(this.$route.params.execReport);
    }
  },
  methods: {
    ...mapActions(['fetchReport']),
    filterBy(screenshots, ip, port) {
      return screenshots.filter((screenshot) => { // eslint-disable-line
        const filterString = `${ip}-${port}`;
        return screenshot.file.indexOf(filterString) > -1;
      });
    },
  },
  computed: {
    ...mapGetters(['executiveReport', 'isLoading']),
    formattedCreatedDate() {
      if (this.executiveReport && this.executiveReport.createdAt) {
        const createdAt = new Date(this.executiveReport.createdAt);
        return createdAt.toLocaleString();
      }
      return null;
    },
    formattedRuntime() {
      if (this.executiveReport && this.executiveReport.createdAt
        && this.executiveReport.finishedAt) {
        const createdAt = new Date(this.executiveReport.createdAt);
        const finishedAt = new Date(this.executiveReport.finishedAt);
        // Calculate difference in milliseconds, convert to seconds
        let diff = (finishedAt.getTime() - createdAt.getTime()) / 1000;
        // Convert to minutes
        diff /= 60;
        // Pull out the decimal values and return just the minutes.
        // The decimal values
        const seconds = diff - Math.floor(diff);
        const formattedMinutes = Math.abs(diff - seconds);
        let formattedSeconds = Math.abs(Math.floor(seconds * 60));
        // Add the 0 if the seconds value is < 10
        if (formattedSeconds < 10) formattedSeconds = `0${formattedSeconds}`;
        return `${formattedMinutes}:${formattedSeconds}`;
      }
      return null;
    },
    domainNameScreenshots() {
      return this.executiveReport.screenshots.filter((s) => s.source === 'domain-name');
    },
    pollingInterval() {
      let interval = 10000; // 10 seconds

      // Over one minute
      if (this.pollingCount > 5) {
        interval = 30000; // 30 seconds
      }

      // Over 3 minutes
      if (this.pollingCount > 9) {
        interval = 60000; // 1 minute
      }

      // Over 10 minutes
      if (this.pollingCount > 16) {
        interval = 300000; // 5 minutes
      }

      return interval;
    },
  },
};
</script>
