<template>
  <div class="raw-data bg-black p-2 m-2">
    <vue-json-pretty
      :deep="3"
      :showLength="true"
      :data="json">
    </vue-json-pretty>
  </div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'RawJSON',
  components: {
    VueJsonPretty,
  },
  props: {
    json: {
      type: Array,
      required: true,
    },
  },
};
</script>
