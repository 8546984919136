<template>
  <div class="flex flex-col relative flex-grow overflow-hidden print:block">
    <router-view :key="$route.fullPath" />
    <div v-if="isInFlight || isOnNewReportPage" class="absolute top-0 left-0 w-full h-full -z-1">
      <div
        class="background-wrapper"
        :class="[
          { 'background-wrapper--step1': isOnNewReportPage },
          { 'background-wrapper--step2': isInFlight && !isOnNewReportPage },
        ]"
      >
        <BackgroundImage class="background" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BackgroundImage from '@/assets/new-report-bg.svg?inline';

export default {
  name: 'ReportIndex',
  components: {
    BackgroundImage,
  },
  async mounted() {
    await this.validateOrganization();
  },
  computed: {
    ...mapGetters(['executiveReport']),
    isInFlight() {
      return this.executiveReport && this.executiveReport.status === 'in_flight';
    },
    isOnNewReportPage() {
      return this.$route.name === 'new-report';
    },
  },
  methods: {
    ...mapActions([
      'validateOrganization',
    ]),
  },
};
</script>
<style>
.background-wrapper {
  @apply absolute left-0 w-full;
  transform-origin: top left;
  transition: transform 500ms ease-in-out;
}
.background-wrapper--step1 {
  transform: translateY(20%) scale(1);
}

.background-wrapper--step2 {
  transform: translate(-10%, 10%) scale(0.6);
}
.background {
  @apply max-w-screen-lg w-full h-full;
  animation: spin 120s linear infinite;
  transform-origin: center center;
}

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
