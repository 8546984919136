<template>
  <div v-if="!!pageReadyToDisplay">
    <div class="bg-black border-0 sm:border-b border-gray-600 report
     print:bg-white print:border-gray-200">
      <div class="flex flex-col py-3 px-4">
        <div>
          <h1 class="inline-block uppercase font-medium text-lg sm:text-2xl
                   ">
            Domain Report
          </h1>
        </div>
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          For Report {{ parentReportID }}
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5
                bg-black border-b border-gray-600
               print:bg-white print:border-gray-200" :class='{
                  "mb-12": !showTrialView,
                  "mb-6": showTrialView,
                }'>
      <div class="
        flex items-center py-3 px-4 row-start-3 col-start-1 col-end-5 justify-end self-stretch
        sm:justify-start sm:row-start-1 print:hidden
      ">
        <Button type="text-small"
                class="self-stretch"
                @onClick="$router.push(
                      { name: 'executive-report',
                        params: {
                          execReport: parentReportID,
                        },
                      })">
          <template v-slot:iconLeft>
            <ArrowLeft class="text-gray-300" />
          </template>
          Back to Executive Report
        </Button>
      </div>
      <div class="flex flex-col row-start-2 col-start-1 col-end-3 border-t
                  sm:row-start-1 sm:col-start-2 sm:border-l sm:border-t-0
                  py-3 px-4 border-gray-600 print:bg-white print:border-gray-200 ">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Input
        </div>
        <div>
          <span v-for="(input, index) in executiveReport.reportInput"
                :key="`input${index}`">
            {{ input }}
          </span>
        </div>
      </div>
      <div
        v-if="isSuperUser && executiveReport && executiveReport.finishedAt"
        class="flex flex-col row-start-2 col-start-3 col-end-4 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-gray-600
                  print:bg-white print:border-gray-200
                  ">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Runtime
        </div>
        <div>
          {{ this.executiveReport.createdAt | formatRuntime(this.executiveReport.finishedAt) }}
        </div>
      </div>
      <div v-if="isSuperUser && currentReportInitiator"
           class="flex flex-col row-start-2 col-start-4 col-end-5 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-gray-600">
        <div class="uppercase font-semibold tracking-widest text-xs text-gray-300">
          Initiated By:
        </div>
        <div>
          {{ currentReportInitiator }}
        </div>
      </div>
      <div v-if="isSuperUser
              && executiveReport
              && executiveReport.directoryBrutforceReturnedNoResults"
           class="flex flex-col row-start-2 col-start-5 col-end-6 border-t
                  sm:row-start-1 sm:border-t-0
                  py-3 px-4 border-red-600">
        <div class="uppercase font-semibold tracking-widest text-xs text-red-600">
          Directory Enumeration Failed
        </div>
      </div>
      <div class="flex flex-row-reverse sm:flex-row items-center border-gray-600
                  row-start-4 col-start-1 col-span-4 border-t
                  sm:row-start-1 sm:col-start-7 sm:col-span-2 sm:border-t-0">
        <div v-if="executiveReport
                   && executiveReport.pastReportDiff
                   && executiveReport.pastReportDiff.id"
             class="flex flex-col w-full py-3 px-4 border-gray-600 border-l">
          <div class="flex justify-end">
            <Checkbox id="diff-checkbox"
                      v-model="isShowingPrevious"
                      class="uppercase text-xs"
                      label="Compare with previous report" />
          </div>
          <div class="flex justify-end">
            <Button type="text-small"
                    @onClick="$router.push(
                      { name: 'executive-report',
                        params: {
                          execReport: executiveReport.pastReportDiff.id,
                        },
                      })">
              Report {{ String(executiveReport.pastReportDiff.id) | zeroPad(4) }}
              <template v-slot:iconRight>
                <ArrowRight class="text-gray-300" />
              </template>
            </Button>
          </div>
        </div>
        <div class="flex w-1/4 items-center justify-center self-stretch
                    border-l sm:w-auto print:hidden
                    border-gray-600">
          <Button type="text-small"
                  class="self-stretch px-4"
                  @onClick="handleDownloadCsv">
            <template v-slot:iconLeft>
              <Print class="text-gray-300" />
            </template>
            CSV
          </Button>
        </div>
        <div class="flex w-1/4 items-center justify-center self-stretch
                    border-l sm:w-auto print:hidden
                    border-gray-600">
          <Button type="text-small"
                  class="self-stretch px-4"
                  @onClick="handlePrint">
            <template v-slot:iconLeft>
              <Print class="text-gray-300" />
            </template>
            Print
          </Button>
        </div>
        <div
          v-if="isSuperUser"
          class="flex w-1/4 items-center justify-center self-stretch
                    sm:border-l sm:w-auto
                    border-gray-600 print:hidden">
          <Button
            type="text-small"
            class="self-stretch px-4"
            @onClick="showAdminModal = !showAdminModal">
            <template v-slot:iconLeft>
              <Pencil class="text-gray-300" />
            </template>
            Edit
          </Button>
        </div>
      </div>
    </div>

    <div class="
      flex flex-col w-100 mb-12 text-center border border-yellow-500
      py-3 mx-4 bg-gray-900
    "  v-if="showTrialView">
      <h2 class="uppercase font-semibold tracking-widest">Trial Mode</h2>
      <p
        class="text-gray-200">
        You are seeing partial results for this report because your account is in Trial Mode.
      </p>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-4 col-gap-4 max-w-screen-lg mx-4 lg:mx-8 mb-8">
      <div class="grid col-start-1 col-span-1 lg:col-span-3">
        <div class="w-full justify-center mb-8
                    ">
        <AttackPathwaysWrapper

          :attackPathwaysCount="attackPathways.length"
          :resolvedAttackPathwaysCount="resolvedAttackPathways.length"
          :isShowingPrevious="isShowingPrevious"
          :hasInsetHeader="true"
        >
          <template v-slot:pathways>
            <AttackPathway v-for="(attackPathway, index) in attackPathways"
                           :outcome="attackPathway.attackPathwayRule.outcome"
                           :rules="attackPathway.attackPathwayRule.rules"
                           :findings="findings"
                           :class="[
                                   {'mt-4': index !== 0},
                                 ]"
                           :newAttackPathways="newAttackPathways"
                           :domains="domains"
                           :domainId="String(currentReportID)"
                           :executiveReportID="String(parentReportID)"
                           :key="`attack-pathway-${index}`"/>
          </template>
          <template v-slot:resolvedPathways>
            <AttackPathway v-for="(attackPathway, index) in resolvedAttackPathways"
                           :outcome="attackPathway.attackPathwayRule.outcome"
                           :rules="attackPathway.attackPathwayRule.rules"
                           :findings="pastFindings"
                           :class="[
                                 {'mt-4': index !== 0},
                               ]"
                           :isResolved="true"
                           :domains="domains"
                           :domainId="String(currentReportID)"
                           :executiveReportID="String(parentReportID)"
                           :key="`resolved-attack-pathway-${index}`"/>
          </template>
        </AttackPathwaysWrapper>
        </div>
      </div>
      <div class="col-start-1 lg:col-start-4 col-span-1 flex flex-col mb-8
                  ">
        <div class="mb-8">
          <Findings :findings="findings"
                    :resolvedFindings="resolvedFindings"
                    :showResolved="isShowingPrevious"/>
        </div>
        <div>
          <Summary :externalHosts="externalHosts || 0"
                   :internalHosts="internalHosts || 0"
                   :technologies="technologies"
                   :previousTechnologies="previousTechnologies"
                   :showPrevious="isShowingPrevious"
                   :isVertical="true"/>
        </div>
      </div>
    </div>
    <div class="page-break mx-4 lg:mx-8 mb-16
                max-w-screen-lg " v-if="isSuperUser && adminViewActive">
      <div class="flex flex-col border border-gray-600 bg-gray-900
                  ">
        <div class="
          flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2 mb-4
        ">
          <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center
                   ">
            <span class="relative inline-block z-10 text-lg sm:text-2xl">
              Raw Data - Admin View
            </span>
          </h2>
        </div>
        <div class="grid grid-cols-1 px-4 pt-2 pb-4">
          <div class="my-2">
            <Button type="text-small"
              class="py-2 text-gray-300 pl-2"
              @onClick="showRawErrorData = !showRawErrorData">
              {{ showOrHideByValue(showRawErrorData) }}
              Raw Error Data [{{ renderCorrectExpandElement(showRawErrorData) }}]
            </Button>
            <RawJSON
              v-if='showRawErrorData'
              :json="executiveReport.rawResponseData.errors" />
          </div>
          <div class="my-2">
            <Button type="text-small"
              class="py-2 text-gray-300 pl-2"
              @onClick="showRawTechnologyData = !showRawTechnologyData">
              {{ showOrHideByValue(showRawTechnologyData) }}
              Raw Technology Data [{{ renderCorrectExpandElement(showRawTechnologyData) }}]
            </Button>
            <RawJSON
              v-if='showRawTechnologyData'
              :json="executiveReport.rawResponseData.aggregated_result.technologies" />
          </div>
          <div class="my-2">
            <Button type="text-small"
              class="py-2 text-gray-300 pl-2"
              @onClick="showRawDNSData = !showRawDNSData">
              {{ showOrHideByValue(showRawDNSData) }}
              Raw DNS Records Data [{{ renderCorrectExpandElement(showRawDNSData) }}]
            </Button>
            <RawJSON
              v-if='showRawDNSData'
              :json="executiveReport.rawResponseData.aggregated_result.dns_records" />
          </div>
          <div class="mt-4 pl-2" v-if="executiveReport.artifacts.length > 0">
            <h3 class="
              uppercase text-gray-300 text-xs font-semibold tracking-widest
            ">
            Artifacts
            </h3>
            <div
              :key='artifact.source + executiveReport.id'
              class="" v-for="artifact in executiveReport.artifacts">
              <a :href="artifact.file" target="_blank">{{ artifact.source }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-break mx-4 lg:mx-8 mb-16
                max-w-screen-lg print:hidden">
      <div class="flex flex-col border border-gray-600 bg-gray-900
                  ">
        <div class="flex flex-col md:flex-row items-start md:items-center -mt-6 ml-2 mb-4">
          <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8 items-center
                   ">
            <LeakedCredentialsIcon
              class="mr-1 " />
            <span class="relative inline-block z-10 text-lg sm:text-2xl">
              Leaked Credentials [{{ credentialsCount }}]
            </span>
          </h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 px-4 pt-2 pb-4">
          <template v-if="credentialsCount && !noNewCreds">
            <div v-if='executiveReport.summary.leakedCredentialsSummary["2"] >= 1'
                 class="md:mr-2">
              <LeakedCredentialsList
                class="mb-4"
                :credentials="credentialsCritical"
                :defaultRowCount="credentialsCritical && credentialsCritical.length"
                :threatLevel="2"
                :isTrial='showTrialView'
                :trialCount='executiveReport.summary.leakedCredentialsSummary["2"]'
              />
            </div>
            <div class="md:ml-2">
              <LeakedCredentialsList
                v-if='executiveReport.summary.leakedCredentialsSummary["1"] >= 1'
                class="mb-8"
                :credentials="credentialsMedium"
                :defaultRowCount="credentialsMedium && credentialsMedium.length"
                :threatLevel="1"
                :isTrial='showTrialView'
                :trialCount='executiveReport.summary.leakedCredentialsSummary["1"]'
              />
              <LeakedCredentialsList
                v-if='executiveReport.summary.leakedCredentialsSummary["0"] >= 1'
                :credentials="credentialsLow"
                :defaultRowCount="credentialsLow && credentialsLow.length"
                :threatLevel="0"
                :isTrial='showTrialView'
                :trialCount='executiveReport.summary.leakedCredentialsSummary["1"]'
              />
            </div>
          </template>
          <template v-else-if="noNewCreds">
            <div class="px-4 pt-4 pb-8 text-center col-span-2">
              <h2 class="uppercase font-medium tracking-widest text-lg text-gray-200">
                No New Leaked Credentials Discovered
              </h2>
            </div>
          </template>
          <template v-else>
            <div class="px-4 pt-4 pb-8 text-center col-span-2">
              <h2 class="uppercase font-medium tracking-widest text-lg text-gray-200">
                No Leaked Credentials Discovered
              </h2>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="page-break print:mt-16 grid col-gap-3 row-gap-4 grid-cols-1 xl:grid-cols-7
                mx-4 lg:mx-8 mb-16
                max-w-screen-lg print:bg-white print:border-gray-200">
      <div class="flex flex-col row-start-1 col-start-1 border border-gray-600 bg-gray-900
                  xl:col-start-1 xl:col-span-6
                  print:bg-white print:border-gray-200">
        <div class="
          flex flex-col lg:flex-row items-start lg:items-center -mt-6 ml-2 mb-4
          print:bg-white print:border-gray-200

        ">
          <h2 ref="hostsSection"
              class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                   border-t border-l border-b border-gray-600 mr-8
                   print:bg-white print:border-gray-200
                   ">
            <span class="relative inline-block z-10 text-lg sm:text-2xl">
              Hosts Found [
                {{ executiveReport.summary.hostSummary.total }}
              ]
            </span>
          </h2>
          <div class="flex flex-col items-start ml-4 mt-4
                      sm:flex-row sm:items-center lg:ml-0 lg:mt-0
                      print:hidden
                      ">
            <RadioButton id="filter1" label="All" value="All" name="filters"
                         v-model="findingsFilter"
                         class="mr-2"/>
            <RadioButton id="filter2"
                         :label="
                          `With Findings [${executiveReport.summary.hostSummary.withFindings}]`
                         "
                         value="Findings"
                         name="filters"
                         v-model="findingsFilter"
                         class="mr-2 mt-2 sm:mt-0"/>
            <RadioButton id="filter3"
                         :label="
                          `Without Findings [${
                            executiveReport.summary.hostSummary.withoutFindings
                            }]`
                          "
                         value="No Findings"
                         name="filters"
                         v-model="findingsFilter"
                         class="mt-2 sm:mt-0 mr-2"/>
            <RadioButton id="filter4"
                         :label="
                          `Screenshots [${
                            executiveReport.summary.screenshotCount
                            }]`
                          "
                         value="Screenshots"
                         name="filters"
                         v-model="findingsFilter"
                         class="mt-2 sm:mt-0"/>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row print:flex-row
        xl:hidden justify-between px-4 mb-4 mt-2
                   print:bg-white print:border-gray-200 ">
          <FilterFinding v-model="filteredFindings"
                         class="w-full mb-2 sm:mb-0 sm:mr-2"
                         :findings="findings"/>
          <FilterTechnology v-model="filteredTechnologies"
                            class="w-full mb-2 sm:mb-0 sm:ml-2 sm:mr-2"
                            :technologies="technologies"/>
          <div class="w-full sm:ml-2 print:hidden">
            <div class="flex flex-col bg-gray-900 border border-gray-600 p-4
                        w-full">
              <Button type="secondary" class="mb-4" @onClick="expandAllHosts">
                Expand All Hosts
              </Button>
              <Button type="secondary" @onClick="collapseAllHosts">
                Collapse All Hosts
              </Button>
            </div>
          </div>
        </div>
        <div v-if="isSuperUser" class="px-4 pb-4 print:hidden">
          <label class="uppercase font-semibold tracking-widest text-xs text-gray-300">
            Additional Directory Enumeration
          </label>
          <div class="uppercase text-medium font-semibold">
            <div v-if="!executiveReport.manualDirectoryEnumerationStatus"
                 class="text-gray-200">
              Not Started
            </div>
            <div v-if="executiveReport.manualDirectoryEnumerationStatus === 'in_flight'"
                 class="text-white-500">
              In Progress
            </div>
            <div v-if="executiveReport.manualDirectoryEnumerationStatus === 'completed'"
                 class="text-green-500">
              Completed
            </div>
            <div v-if="executiveReport.manualDirectoryEnumerationStatus === 'failed'"
                 class="text-red-500">
              Failed
            </div>
            <div v-if="executiveReport.manualDirectoryEnumerationTimestamp"
                class="tracking-widest text-xs text-gray-300">
              {{ executiveReport.manualDirectoryEnumerationTimestamp | formatTimestamp }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-5 xl:grid-cols-5 mb-4 mt-2 print:bg-white xl:pr-32
                    print:border-gray-200"
             :class="{'flex-grow': filteredHosts.length === 0}" id="hosts-container">
          <masonry class="col-span-5 xl:col-span-5 print:break-inside"
                   :cols="{default: 2, 400: 1}">
            <Host class="col-span-5 xl:col-span-4 mb-2 mx-4 print:break-inside"
                  v-for="(host, index) in paginatedFilteredHosts"
                  @dirEnumTargetUpdated="updateDirEnumTargets"
                  :rootDomain="domains[0] && domains[0].name"
                  :isSuperuser="currentUser && currentUser.isSuperuser"
                  :targetsForDictEnum="targetsForDictEnum"
                  :rawData='rawDataForHostByIPAddress(host.ip)'
                  :ip="host.ip || ''"
                  :category="host.category || ''"
                  :categoryExplanation="host.categoryExplanation || ''"
                  :country="host.country || ''"
                  :asn="host.asn || ''"
                  :org="host.org || ''"
                  :subdomains="host.subdomains"
                  :technologies="host.technologies"
                  :ports="host.ports"
                  :screenshots="getScreenshotsBySubdomains(host.subdomains, index) || []"
                  :findings="host.findings.rules"
                  :forceExpand="expandAll"
                  :key="`host-${index}}`"/>

          </masonry>
          <div ref="observed" class="col-span-5 h-1 print:hidden"></div>

          <div v-if="isShowingPrevious && (removedHosts.length > 0 || filteredHosts.length > 0)"
               class="grid xl:grid-cols-6 col-span-6 mb-2
               border-t print:border-none border-gray-600 pt-6 mt-8">
            <div class="col-span-5 flex -mt-12 w-full justify-center mb-4">
              <h2 class="relative inline-flex uppercase px-4 py-1 bg-gray-900
                         border border-gray-600 transform skew-x-20 max-h-12 header-wrapper">
                <span class="
                  relative inline-block z-10 text-2xl transform -skew-x-20 header-wrapper
                ">
                  Removed Hosts [{{ removedHosts.length }}]
                </span>
              </h2>
            </div>
            <div v-if="showRemovedHosts" class="col-span-5 mx-4">
              <masonry class="col-span-5 xl:col-span-5 print:break-inside"
                       :cols="{default: 2, 400: 1}">
                <Host class="col-span-5 xl:col-span-4 mb-2 mx-4 print:break-inside"
                      v-for="(host, index) in removedHosts"
                      :rawData='rawDataForHostByIPAddress(host.ip)'
                      :ip="host.ip || ''"
                      :category="host.category || ''"
                      :categoryExplanation="host.categoryExplanation || ''"
                      :country="host.country || ''"
                      :asn="host.asn || ''"
                      :org="host.org || ''"
                      :subdomains="host.subdomains"
                      :technologies="host.technologies"
                      :ports="host.ports"
                      :screenshots="getScreenshotsBySubdomains(host.subdomains, index) || []"
                      :findingsCount="host.findings.count"
                      :findings="host.findings.rules"
                      :forceExpand="expandAll"
                      :key="`removed-host-${index}}`"/>
              </masonry>

            </div>
            <Button type="text-small"
                    class="col-span-5 w-full py-4 text-gray-300"
                    @onClick="showRemovedHosts = !showRemovedHosts">
              {{ showRemovedHosts ? 'Hide' : 'Show' }}
              <template v-slot:iconRight>
                <Chevron class="text-gray-200 transform
                    transition-transform duration-200 linear"
                         :class="{'-rotate-180': showRemovedHosts}"/>
              </template>
            </Button>
          </div>
          <div v-if="findingsFilter !== 'Screenshots'">
            <p v-if="(!isShowingPrevious
                && filteredHosts.length === 0)
                || (isShowingPrevious
                && removedHosts.length === 0
                && filteredHosts.length === 0)"
               class="flex justify-center items-center uppercase font-medium tracking-widest text-lg
             text-gray-200 text-center mt-4 mb-8 col-span-5">
              No Hosts to Display
            </p>
          </div>
          <div v-show="findingsFilter === 'Screenshots'" class="col-span-5 mx-4">
            <div
                id="screen-shot-container"
                class="grid col-gap-3 row-gap-4 grid-cols-1
      md:grid-cols-4 print:grid-cols-4 ml-3 mr-4 mb-4 mt-2">
              <div v-for="(screenshot, index) in screenshots"
                   :key="`screenshot-${index}`"
                   class="border border-gray-600 px-1 pt-1 bg-gray-800">
                <a :href="screenshot.file"
                   target="_blank"
                   rel="noopener noreferrer">
                  <img :src="screenshot.file"
                       :alt="screenshotAltText(screenshot)" loading='lazy'/>
                </a>
                <div class="flex flex-col my-3">
                  <p v-if="screenshot.domainName"
                     class="text-white text-sm text-center font-semibold tracking-wider break-all">
                    {{ screenshot.domainName }}
                  </p>
                  <p v-if="screenshot.ipAddress && screenshot.port"
                     class="text-white text-sm text-center font-semibold tracking-wider break-all">
                    {{ screenshot.ipAddress }}:{{ screenshot.port }}
                  </p>
                </div>
              </div>
            </div>
            <div ref="screenshotObserved" class="h-1 print:hidden"></div>
          </div>

        </div>
        <div class="grid grid-cols-5 xl:grid-cols-6 mb-4 mt-2
                    "
              v-if="showTrialView && filteredHosts.length > 0">
          <p class="
            italic text-center col-span-5 text-gray-300
          " v-if='executiveReport.summary.hostSummary.total > 1'>
            We found a total of {{ executiveReport.summary.hostSummary.total }}
            {{ 'host' | pluralize(executiveReport.summary.hostSummary.total) }},
            upgrade to see them all.
          </p>
          <p class='italic text-center col-span-5 text-gray-300' v-else>
            Upgrade to see all discovered hosts.
          </p>
        </div>
      </div>
      <div class="hidden xl:flex flex-col col-span-2 col-start-6 row-start-1 -mt-4 xl:pl-16
                  ">
        <FilterFinding v-model="filteredFindings"
                       class="mb-3"
                       :findings="findings"/>
        <FilterTechnology v-model="filteredTechnologies"
                          class="mb-3"
                          :technologies="technologies"/>
        <div class="flex flex-col justify-between bg-gray-900 border
         border-gray-600 p-4 mb-3 print:hidden">
          <Button type="secondary" class="mb-4" @onClick="expandAllHosts">
            Expand All Hosts
          </Button>
          <Button type="secondary" @onClick="collapseAllHosts">
            Collapse All Hosts
          </Button>
        </div>
      </div>
    </div>
    <div class="hidden flex-col border border-gray-600 bg-gray-900 mx-6 sm:mx-8 mb-8
                print:bg-white print:border-gray-200 print:flex">
      <div class="-mt-6 ml-2 mb-4 print:bg-white print:border-gray-200">
        <h2 class="triangle relative inline-flex uppercase pl-3 pr-2 py-1 bg-gray-900
                 border-t border-l border-b border-gray-600 print:bg-white print:border-gray-200">
          <span class="relative inline-block z-10 text-lg sm:text-2xl
          print:bg-white print:border-gray-200">
            Screenshots from Domains [{{ executiveReport.summary.screenshotCount }}]
          </span>
        </h2>
      </div>
      <div
          id="screen-shot-container"
          class="grid col-gap-3 row-gap-4 grid-cols-1
      md:grid-cols-4 print:grid-cols-4 ml-3 mr-4 mb-4 mt-2">
        <div v-for="(screenshot, index) in screenshots"
             :key="`screenshot-${index}`"
             class="border border-gray-600 px-1 pt-1 bg-gray-800">
          <a :href="screenshot.file"
             target="_blank"
             rel="noopener noreferrer">
            <img :src="screenshot.file"
                 :alt="screenshotAltText(screenshot)" loading='lazy'/>
          </a>
          <div class="flex flex-col my-3">
            <p v-if="screenshot.domainName"
               class="text-white text-sm text-center font-semibold tracking-wider break-all">
              {{ screenshot.domainName }}
            </p>
            <p v-if="screenshot.ipAddress && screenshot.port"
               class="text-white text-sm text-center font-semibold tracking-wider break-all">
              {{ screenshot.ipAddress }}:{{ screenshot.port }}
            </p>
          </div>
        </div>
      </div>
      <div class="mb-4 mt-2 "
            v-if="showTrialView">
        <p class="
          italic text-center text-gray-300
        ">
          We took a total of {{ executiveReport.summary.screenshotCount }}
          {{ 'screenshot' | pluralize(executiveReport.summary.screenshotCount) }}, upgrade to see
          them all.
        </p>
      </div>
    </div>
    <AdminModal

      v-if="showAdminModal"
      :reportId='Number(parentReportID)'
      :findings="findingsAll"
      :attackPathwayFindings="attackPathwaysAll"
      :parentReportID="String(parentReportID)"
      @close='handleClose'
      />

    <ReportActionBar
      v-if="(targetsForDictEnum && targetsForDictEnum.length)
      || requestDirectoryEnumerationStatus"
      @cancelAction="clearHostsForDictEnum"
      @submitAction="requestDictEnum"
      :requestStatus="requestDirectoryEnumerationStatus"
      :targets="targetsForDictEnum"
      />

  </div>

  <div v-else-if="(!isLoading && !pendingRequestCount) && isFailed"
       class="flex justify-center items-center my-12 max-w-screen-lg
              ">
    <ReportErrorMessage key="failed">
      <template v-slot:main>
        Report {{ String(currentReportID) | zeroPad(4) }} Failed
      </template>
      <template v-slot:additional>
        <template v-if="executiveReport.hasErrors">
          [ Errors Encountered ]
        </template>
        <template v-if="!executiveReport.isAcceptingWebTraffic">
          [ Not Accepting Web Traffic ]
        </template>
      </template>
    </ReportErrorMessage>
  </div>
  <div v-else-if="(!isLoading && !pendingRequestCount) && !executiveReport"
       class="flex justify-center items-center my-12 max-w-screen-lg
              ">
    <ReportErrorMessage key="missing">
      <template v-slot:main>
        Report Missing
      </template>
    </ReportErrorMessage>
  </div>
  <div v-else-if="(!isLoading && !pendingRequestCount) && isInFlight">
    Running Orbital...
  </div>
  <div v-else-if="!pageReadyToDisplay"
       class="flex flex-grow items-start fade-in">
    <div
        class="relative top-0 left-0 w-full h-full">
      <div class="background-wrapper background-wrapper--step2">
        <BackgroundImage class="background" />
      </div>
    </div>
    <div class="w-full mx-8 md:w-5/12 md:mt-32 text-center">
      <h2 class="uppercase text-2xl mb-2">
        Loading...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ArrowLeft from '@/assets/arrow_left.svg?inline';
import ArrowRight from '@/assets/arrow_right.svg?inline';
import AttackPathway from '@/components/AttackPathway.vue';
import BackgroundImage from '@/assets/new-report-bg.svg?inline';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Findings from '@/components/Findings.vue';
import Summary from '@/components/Summary.vue';
import Print from '@/assets/print.svg?inline';
import Chevron from '@/assets/chevron.svg?inline';
import RadioButton from '@/components/RadioButton.vue';
import Host from '@/components/Host.vue';
import FilterFinding from '@/components/FilterFinding.vue';
import FilterTechnology from '@/components/FilterTechnology.vue';
import AttackPathwaysWrapper from '@/components/AttackPathwaysWrapper.vue';
import ReportActionBar from '@/components/ReportActionBar.vue';
import ReportErrorMessage from '@/components/ReportErrorMessage.vue';
import LeakedCredentialsIcon from '@/assets/asterisk.svg?inline';
import LeakedCredentialsList from '@/components/LeakedCredentialsList.vue';
import AdminModal from '@/components/AdminModal.vue';
import Pencil from '@/assets/pencil.svg?inline';
import RawJSON from '@/components/Admin/RawJSON.vue';
import api from '@/store/api';

export default {
  name: 'DomainReport',
  components: {
    AdminModal,
    ArrowLeft,
    ArrowRight,
    AttackPathway,
    AttackPathwaysWrapper,
    BackgroundImage,
    Button,
    Checkbox,
    Chevron,
    FilterFinding,
    FilterTechnology,
    Findings,
    Host,
    LeakedCredentialsIcon,
    LeakedCredentialsList,
    Pencil,
    Print,
    RadioButton,
    RawJSON,
    ReportActionBar,
    ReportErrorMessage,
    Summary,
  },
  data() {
    return {
      showAdminModal: false,
      pollingCount: 0,
      hosts: [],
      updateForPrint: false,
      isShowingPrevious: false,
      showResolvedPathways: true,
      showRemovedHosts: true,
      findingsFilter: 'Findings',
      filteredFindings: [],
      filteredTechnologies: [],
      pastHosts: [],
      expandAll: false,
      observer: null,
      screenShotObserver: null,
      shouldScrollToHosts: false,
      showRawErrorData: false,
      showRawTechnologyData: false,
      showRawDNSData: false,
      targetsForDictEnum: [],
      page: 0,
      screenShotPage: 0,
    };
  },
  async mounted() {
    if (this.currentReportID) {
      await this.fetchReportAsync(this.currentReportID);
      // eslint-disable-next-line max-len
      // If we dont have a report or the domain report parent does not match with executive report, redirect back to the executive report level
      if (!this.executiveReport || (this.executiveReport.parentReportID
          && (this.executiveReport.parentReportID !== this.parentReportID))) {
        this.$router.replace({
          name: 'executive-report',
          params: {
            // eslint-disable-next-line max-len
            execReport: this.$route.params.execReport || (this.executiveReport && this.executiveReport.parentReportID),
          },
        });
      } else {
        await this.fetchReportScreenshotsAsync(this.currentReportID);
      }
    }
    if (this.executiveReport) {
      if (this.isInFlight) {
        this.pollingCount += 1;
        setTimeout(async () => this.fetchReportAsync(this.currentReportID), this.pollingInterval);
      }

      if (this.isCompleted) {
        this.hosts = [];
        this.populateHosts(this.executiveReport.aggregatedResult);
        if (this.pastReportID) {
          await this.fetchPastReport(this.pastReportID);
          await this.fetchPastReportScreenshots(this.pastReportID);
          this.getRemovedHosts();
        }
      }
    }

    this.setFindingFilters();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.screenShotObserver) {
      this.screenShotObserver.disconnect();
    }
  },
  computed: {
    ...mapGetters([
      'noNewCreds',
      'executiveReport',
      'reportScreenshots',
      'adminViewActive',
      'selectedView',
      'isLoading',
      'pastReport',
      'pastScreenshots',
      'isSuperUser',
      'pendingRequestCount',
      'filterFindingEvent',
      'rawDataForHostByIPAddress',
      'requestDirectoryEnumerationStatus',
      'currentUser',
      'currentReportInitiator',
    ]),
    domains() {
      if (!this.executiveReport) return [];
      const domains = [];
      try {
        this.executiveReport.children.forEach((child) => {
          domains.push({
            id: child.id,
            name: child.reportInput[0],
            findingsCount: child.findings ? child.findings.length : 0,
            attackPathwaysCount: child.attackPathwayFindingsCount,
            screenshots: child.screenshots,
          });
        });
      } catch (e) {
        console.log(e);
      }

      if (domains && domains.length === 0) {
        domains.push({
          id: this.executiveReport.id,
          name: this.executiveReport.reportInput[0],
          findingsCount: this.executiveReport.findings ? this.executiveReport.findings.length : 0,
          attackPathwaysCount: this.executiveReport.summary.attackPathwaysCount,
          screenshots: this.executiveReport.screenshots,
        });
      }
      return domains;
    },
    isInFlight() {
      return this.executiveReport && this.executiveReport.status === 'in_flight';
    },
    isFailed() {
      return this.executiveReport && this.executiveReport.status === 'failed';
    },
    isCompleted() {
      return this.executiveReport && this.executiveReport.status === 'completed';
    },
    showTrialView() {
      return (this.selectedView === 'customer' && this.executiveReport.initiatedBy.organization.isTrial) || this.currentUser.organization.isTrial;
    },
    currentReportID() {
      return this.$route.params.domainReport;
    },
    newAttackPathways() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemAdded) {
        // eslint-disable-next-line max-len
        const added = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemAdded;
        const attackPathKeys = Object.keys(added).filter((key) => key.includes('attackPathwayFindings'));
        const pathwaysArray = [];
        attackPathKeys.forEach((key) => pathwaysArray.push(added[key]));
        return pathwaysArray;
      }
      return [];
    },
    pastReportID() {
      return this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.id;
    },
    parentReportID() {
      return this.$route.params.execReport;
    },
    pollingInterval() {
      let interval = 10000; // 10 seconds

      // Over one minute
      if (this.pollingCount > 5) {
        interval = 30000; // 30 seconds
      }

      // Over 3 minutes
      if (this.pollingCount > 9) {
        interval = 60000; // 1 minute
      }

      // Over 10 minutes
      if (this.pollingCount > 16) {
        interval = 300000; // 5 minutes
      }

      return interval;
    },
    findings() {
      const { domains } = this;
      const domainsForFindings = domains.map((d) => ({ domain: d.name, reportId: d.id }));
      return (this.executiveReport
             && this.executiveReport.aggregatedResult
             && this.executiveReport.aggregatedResult.findings
               .filter((finding) => finding.visible)
               .map((f) => ({ ...f, domains: domainsForFindings }))) ?? [];
    },
    findingsAll() {
      return this.executiveReport
        && this.executiveReport.aggregatedResult
        && this.executiveReport.aggregatedResult.findings;
    },
    pastFindings() {
      return (this.pastReport
        && this.pastReport.aggregatedResult
        && this.pastReport.aggregatedResult.findings)
        || (this.pastReport
          && this.pastReport.executiveSummary
          && this.pastReport.executiveSummary.findings);
    },
    resolvedFindings() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const findingsKeys = Object.keys(removed).filter((key) => key.includes('findings'));
        const findingsArray = [];
        findingsKeys.forEach((key) => findingsArray.push(removed[key]));
        return findingsArray;
      }
      return [];
    },
    isPrint() {
      return window.matchMedia('print').matches;
    },
    externalHosts() {
      return (this.executiveReport.executiveSummary
              && this.executiveReport.executiveSummary.externalIpsCount)
              || this.executiveReport.summary.externalIpsCount;
    },
    internalHosts() {
      return (this.executiveReport.executiveSummary
              && this.executiveReport.executiveSummary.internalIpsCount)
              || this.executiveReport.summary.internalIpsCount;
    },
    technologies() {
      return (this.executiveReport.executiveSummary
              && this.executiveReport.executiveSummary.technologies)
              || this.executiveReport.summary.technologies;
    },
    previousTechnologies() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const techKeys = Object.keys(removed).filter((key) => key.includes('technologies'));
        const techArray = [];
        techKeys.forEach((key) => techArray.push(removed[key]));
        return techArray;
      }
      return [];
    },
    attackPathways() {
      return (this.executiveReport
          && this.executiveReport.aggregatedResult
          && this.executiveReport.aggregatedResult.attackPathwayFindings
            .filter((finding) => finding.visible)) ?? [];
    },
    attackPathwaysAll() {
      return this.executiveReport
        && this.executiveReport.aggregatedResult
        && this.executiveReport.aggregatedResult.attackPathwayFindings;
    },
    resolvedAttackPathways() {
      if (this.executiveReport
        && this.executiveReport.pastReportDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0]
        && this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved) {
        // eslint-disable-next-line max-len
        const removed = this.executiveReport.pastReportDiff.executiveSummaryDiff[0].iterableItemRemoved;
        const attackPathKeys = Object.keys(removed).filter((key) => key.includes('attackPathwayFindings'));
        const pathwaysArray = [];
        attackPathKeys.forEach((key) => pathwaysArray.push(removed[key]));
        return pathwaysArray;
      }
      return [];
    },
    hostsWithFindingsCount() {
      return this.hosts.filter((host) => host.findings.count).length;
    },
    hostsWithoutFindingsCount() {
      return this.hosts.length - this.hostsWithFindingsCount;
    },
    filteredHosts() {
      let tempHosts = [];

      switch (this.findingsFilter) {
        case 'Findings':
          tempHosts = this.hosts.filter((host) => host.findings.count);
          break;
        case 'No Findings':
          tempHosts = this.hosts.filter((host) => !host.findings.count);
          break;
        case 'Screenshots':
          tempHosts = [];
          break;
        default:
          tempHosts = this.hosts;
      }

      if (this.filteredFindings.length) {
        // eslint-disable-next-line max-len
        tempHosts = tempHosts.filter((host) => host.findings.rules.some((rule) => this.filteredFindings.includes(rule)));
      }

      if (this.filteredTechnologies.length) {
        // eslint-disable-next-line max-len
        tempHosts = tempHosts.filter((host) => host.technologies && host.technologies.some((tech) => this.filteredTechnologies.includes(tech.description)));
      }

      return tempHosts;
    },
    paginatedFilteredHosts() {
      return this.updateForPrint
        ? this.filteredHosts
        : [...this.filteredHosts.slice(0, (this.page + 1) * 100)];
    },
    maxPageSizeHosts() {
      return Math.ceil(this.filteredHosts.length / 100);
    },
    removedHosts() {
      const currentHostIPs = this.hosts.map((host) => host.ip);
      return this.pastHosts.filter((pastHost) => !currentHostIPs.includes(pastHost.ip));
    },
    credentialsCount() {
      return (this.executiveReport.summary
        && this.executiveReport.summary.leakedCredentialsSummary
        && Object.keys(this.executiveReport.summary.leakedCredentialsSummary).reduce(
          (a, b) => a + this.executiveReport.summary.leakedCredentialsSummary[b], 0,
        )
      )
      || 0;
    },
    credentialsCritical() {
      return this.executiveReport.summary
             && this.executiveReport.summary.leakedCredentials
             && this.executiveReport.summary.leakedCredentials
               .filter((cred) => cred.severity === 2);
    },
    credentialsMedium() {
      return this.executiveReport.summary
        && this.executiveReport.summary.leakedCredentials
        && this.executiveReport.summary.leakedCredentials
          .filter((cred) => cred.severity === 1);
    },
    credentialsLow() {
      return this.executiveReport.summary
        && this.executiveReport.summary.leakedCredentials
        && this.executiveReport.summary.leakedCredentials
          .filter((cred) => cred.severity === 0);
    },
    screenshots() {
      return this.updateForPrint
        ? this.reportScreenshots
        : this.reportScreenshots.slice(0, (this.screenShotPage + 1) * 20);
    },
    pageReadyToDisplay() {
      return !!((!this.isLoading && !this.pendingRequestCount) && this.isCompleted);
    },
  },
  methods: {
    ...mapActions(['fetchReport', 'fetchPastReport', 'fetchPastReportScreenshots',
      'fetchReportScreenshots', 'requestDirectoryEnumeration', 'updateRequestDirectoryEnumerationStatus',
      'fetchReportAsync', 'fetchReportScreenshotsAsync']),
    resetData() {
      this.pollingCount = 0;
      this.hosts = [];
      this.pastHosts = [];
    },
    loadMore() {
      this.page += 1;
    },
    loadMoreScreenshots() {
      this.screenShotPage += 1;
    },
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.loadMore();
        }
      });
      const element = this.$refs.observed;
      this.observer.observe(element);
    },
    createScreenshotObserver() {
      this.screenShotObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.loadMoreScreenshots();
        }
      });
      const element = this.$refs.screenshotObserved;
      this.screenShotObserver.observe(element);
    },
    renderCorrectExpandElement(value) {
      return value ? '-' : '+';
    },
    showOrHideByValue(value) {
      return value ? 'Hide' : 'Show';
    },
    screenshotAltText(screenshot) {
      if (screenshot.ipAddress && screenshot.port) {
        return `Screenshot of ${screenshot.ipAddress}:${screenshot.port}`;
      }

      if (screenshot.domainName) {
        return `Screenshot of ${screenshot.domainName}`;
      }
      return `Screenshot of ${this.executiveReport.reportInput[0]}`;
    },
    populateHosts(data, isPastReport = false) {
      data.ips.forEach((host) => {
        const {
          ip,
          category,
          categoryExplanation,
          country,
          asn,
          org,
          subdomains,
          ports,
        } = host;

        const tempFindings = {
          count: 0,
          rules: [],
        };

        const tempTechnologies = [];

        data.findings.forEach((finding) => {
          if (finding.ipAddress === ip) {
            tempFindings.count += 1;
            tempFindings.rules.push(finding.rule.outcome);
          }
        });

        data.technologies.forEach((technology) => {
          if (technology.ipAddress === ip) {
            tempTechnologies.push(technology);
          }
        });

        if (isPastReport) {
          this.pastHosts.push({
            ip,
            category,
            categoryExplanation,
            country,
            asn,
            org,
            subdomains,
            technologies: tempTechnologies,
            ports,
            findings: { ...tempFindings },
          });
        } else {
          this.hosts.push({
            ip,
            category,
            categoryExplanation,
            country,
            asn,
            org,
            subdomains,
            technologies: tempTechnologies,
            ports,
            findings: { ...tempFindings },
          });
          this.hosts.sort((a, b) => (b.findings.count ? 1 : -1));
        }
      });
      // if user is a trial user we should check to see if this should be surfaced or not
      // this host should be in the current host list or in
      // the removed host list on the execsummary diff
      if (this.currentUser.organization.isTrial) {
        const currentIpList = this.executiveReport.aggregatedResult.ips.map((ip) => ip.ip);
        let ipsDiff = [];
        if (this.executiveReport.pastReportDiff && this.executiveReport.pastReportDiff.ipsDiff) {
          ipsDiff = this.executiveReport.pastReportDiff.ipsDiff;
        }
        const ipsInDiff = ipsDiff.map((entry) => entry.ip);
        this.pastHosts = this.pastHosts.filter(
          (h) => currentIpList.includes(h.ip) || ipsInDiff.includes(h.ip),
        );
      }

      this.populateHostScreenshots(isPastReport);
    },
    populateHostScreenshots(isPastReport) {
      if (isPastReport) {
        this.pastScreenshots.forEach((screenshot) => {
          this.pastHosts.forEach((host) => {
            if (host.ip === screenshot.ipAddress) {
              host.ports.forEach((port) => {
                if (port.portid === String(screenshot.port)) {
                  port.screenshot = screenshot.file;
                }
              });
            }
          });
        });
      } else {
        this.reportScreenshots.forEach((screenshot) => {
          this.hosts.forEach((host) => {
            if (host.ip === screenshot.ipAddress) {
              host.ports.forEach((port) => {
                if (port.portid === String(screenshot.port)) {
                  port.screenshot = screenshot.file;
                }
              });
            }
          });
        });
      }
    },
    getRemovedHosts() {
      if (this.pastReport) {
        this.populateHosts(this.pastReport.aggregatedResult, true);
      }
      return [];
    },
    handlePrint() {
      this.findingsFilter = 'All';
      this.expandAllHosts();
      this.updateForPrint = true;
      window.setTimeout(() => { window.print(); }, 500);
    },
    handleClose(redirectRoute, shouldFetchReport = false) {
      this.showAdminModal = false;
      if (redirectRoute) {
        this.$router.push({ name: redirectRoute });
      }
      if (shouldFetchReport) {
        this.fetchReport(this.currentReportID);
        this.fetchReportScreenshots(this.currentReportID);
      }
    },
    async handleDownloadCsv() {
      const response = await api.get(`reports/${this.currentReportID}/csv`, { responseType: 'blob' });
      const href = URL.createObjectURL(response.data.rawResponseData);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `report-${this.currentReportID}.csv`); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    expandAllHosts() {
      console.log(this.maxPageSizeHosts);
      this.page = this.maxPageSizeHosts;
      this.expandAll = true;
      window.setTimeout(() => { this.expandAll = null; }, 100);
    },
    collapseAllHosts() {
      this.expandAll = false;
      window.setTimeout(() => { this.expandAll = null; }, 100);
    },
    setFindingFilters() {
      if (this.filterFindingEvent && this.filterFindingEvent.payload) {
        this.filteredFindings.push(this.filterFindingEvent.payload);
        this.$store.commit('UPDATE_FILTER_FINDING_EVENT', '');
        if (this.isLoading) {
          this.shouldScrollToHosts = true;
        } else {
          this.scrollToHosts();
        }
      }
    },
    scrollToHosts() {
      this.$nextTick(() => {
        this.$refs.hostsSection.scrollIntoView({ behavior: 'smooth', alignToTop: true });
        this.shouldScrollToHosts = false;
      });
    },
    getScreenshotsBySubdomains(subdomains) {
      const filteredScreenshots = this.reportScreenshots
        .filter((screenshot) => screenshot.source.includes('domain-name'));

      const screenshots = [];

      subdomains.forEach((subdomain) => {
        const screenshot = filteredScreenshots
          .find((screenshot) => screenshot.domainName
            .includes(`://${subdomain}.${this.executiveReport.reportInput[0]}`));
        if (screenshot) screenshots.push(screenshot);
      });
      return screenshots;
    },
    updateDirEnumTargets(target) {
      if (!this.targetsForDictEnum.includes(target)) {
        this.targetsForDictEnum.push(target);
      } else {
        this.targetsForDictEnum = this.targetsForDictEnum.filter((value) => value !== target);
      }
    },
    addTargetForDictEnum(host) {
      if (!this.targetsForDictEnum.includes(host)) this.targetsForDictEnum.push(host);
    },
    removeTargetFromDictEnum(host) {
      const { targetsForDictEnum } = this;
      if (targetsForDictEnum.includes(host)) {
        this.targetsForDictEnum = targetsForDictEnum.filter((value) => value !== host);
      }
    },
    clearHostsForDictEnum() {
      this.targetsForDictEnum = [];
    },
    requestDictEnum() {
      const report = this.currentReportID;
      const hosts = this.targetsForDictEnum;
      this.requestDirectoryEnumeration({ hosts, report }).then(() => {
        this.clearHostsForDictEnum();
      });
    },
  },
  watch: {
    $route() {
      this.resetData();
      if (this.currentReportID) {
        this.fetchReport(this.currentReportID);
      }
    },
    pageReadyToDisplay(ready) {
      if (ready) {
        this.$nextTick(() => {
          this.createObserver();
        });
      }
    },
    findingsFilter(filter) {
      if (filter === 'Screenshots') {
        this.$nextTick(() => {
          this.createScreenshotObserver();
        });
      }
    },
    pastReport() {
      this.getRemovedHosts();
    },
    filterFindingEvent() {
      this.setFindingFilters();
    },
    pendingRequestCount(count) {
      if (this.shouldScrollToHosts && !this.isLoading && !count) {
        this.scrollToHosts();
      }
    },
    targetsForDictEnum() {
      if (this.targetsForDictEnum && this.targetsForDictEnum.length) {
        this.updateRequestDirectoryEnumerationStatus(null);
      }
    },
  },
};
</script>

<style scoped>
.background {
  @apply max-w-screen-lg w-full h-full;
  animation: spin 120s linear infinite;
  transform-origin: center center;
}

@media print { body { -webkit-print-color-adjust: exact; font-size: 12px !important; }
  body * { font-size: 12px !important;} }
.triangle::before {
  @apply absolute border-b bg-gray-900 border-t border-gray-600 border-r z-0
  transform skew-x-20;
  top: -1px;
  left: calc(100% - 0.75rem);
  width: calc(1rem + 12px);
  height: calc(100% + 2px);
  content: '';
}

.page-break {
  page-break-before: always;
}

.background-wrapper--step2 {
  transform: translate(-10%, 10%) scale(0.6);
}

.header-wrapper {
  max-height: 3rem !important;
}
</style>
